<form (keydown.enter)="loadData()">
  <div class="page-content">
    <h3>{{'menu.OvertimePractice' | translate}}</h3>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex.gt-sm="40%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <mat-form-field fxFlex="grow" [formGroup]="searchForm">
          <input matInput formControlName="searchName" placeholder="{{'common.FilterByNumberOrDescr' | translate}}">
          <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchForm.get('searchName').value" (click)="clearSearchField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="isNewOvertimeRule" class="custom-button" (click)="loadData()">{{'common.Refresh' | translate}}</button>
      </div>
      <div fxFlex.gt-sm="60%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <button mat-raised-button (click)="showFirstRecord()" [disabled]="isNewOvertimeRule || (currentIndex === 0)"><span class="material-icons">first_page</span></button>
        <button mat-raised-button (click)="showPreviousRecord()" [disabled]="isNewOvertimeRule || (currentIndex === 0)"><span class="material-icons">navigate_before</span></button>
        <button mat-raised-button (click)="showNextRecord()" [disabled]="isNewOvertimeRule || (currentIndex === overtimeRules.length - 1)"><span class="material-icons">navigate_nextr</span></button>
        <button mat-raised-button (click)="showLastRecord()" [disabled]="isNewOvertimeRule || (currentIndex === overtimeRules.length - 1)"><span class="material-icons">last_page</span></button>
        <button mat-raised-button color="primary" [disabled]="!writeRight" (click)="createOvertimeRule()">{{'overtimerule.NewOvertimeRule' | translate}}</button>
        <button mat-raised-button color="warn" [disabled]="!writeRight || isNewOvertimeRule" (click)="confirmDelete(overtimeRule)">{{'overtimerule.DeleteOvertimeRule' | translate}}</button>
      </div>
    </div>
    <div [formGroup]="overtimeRuleForm">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px">
        <div fxFlex.gt-sm="50%">
          <div fxLayout="column" fxLayoutGap="2px">
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="80px">
                <mat-label>{{'common.Number' | translate}}</mat-label>
                <input matInput max="32767" formControlName="overtime_id" [readonly]="!isNewOvertimeRule" [attr.tabindex]="!isNewOvertimeRule ? -1 : null" />
              </mat-form-field>
              <mat-form-field [floatLabel]="floatLabels" fxFlex="250px">
                <mat-label>{{'common.Descr' | translate}}</mat-label>
                <input matInput formControlName="description" maxlength="40" />
              </mat-form-field>
              <div fxFlex="20px"></div> <!-- Spacer for additional spacing -->
              <mat-slide-toggle formControlName="passivated">{{'wtgroup.Passivated' | translate}}</mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <mat-grid-list cols="3" gutterSize="20px" rowHeight="450px">
        <mat-grid-tile>
          <div class="column-container">
            <mat-card>
              <mat-card-header>
                <mat-card-title> {{'overtimerule.OvertimeWorktime' | translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="AgreedWorkTimeLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('agreed_worktime')?.value"
                                      (timeChange)="onTimeUpdated1($event)"></app-time-converter>
                  <app-time-converter [label]="Overtime1AmountLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('overtime1_amount')?.value"
                                      (timeChange)="onTimeUpdated2($event)"></app-time-converter>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="RegularWorktimeLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('regular_worktime')?.value"
                                      (timeChange)="onTimeUpdated3($event)"></app-time-converter>
                  <app-time-converter [label]="Overtime2AmountLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('overtime2_amount')?.value"
                                      (timeChange)="onTimeUpdated4($event)"></app-time-converter>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="column-container">
            <mat-card>
              <mat-card-header>
                <mat-card-title> {{'overtimerule.OvertimeWorktime' | translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="AgreedWorkTimeLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('agreed_worktime')?.value"
                                      (timeChange)="onTimeUpdated1($event)"></app-time-converter>
                  <app-time-converter [label]="Overtime1AmountLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('overtime1_amount')?.value"
                                      (timeChange)="onTimeUpdated2($event)"></app-time-converter>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="RegularWorktimeLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('regular_worktime')?.value"
                                      (timeChange)="onTimeUpdated3($event)"></app-time-converter>
                  <app-time-converter [label]="Overtime2AmountLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('overtime2_amount')?.value"
                                      (timeChange)="onTimeUpdated4($event)"></app-time-converter>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="column-container">
            <mat-card>
              <mat-card-header>
                <mat-card-title> {{'overtimerule.OvertimeWorktime' | translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="AgreedWorkTimeLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('agreed_worktime')?.value"
                                      (timeChange)="onTimeUpdated1($event)"></app-time-converter>
                  <app-time-converter [label]="Overtime1AmountLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('overtime1_amount')?.value"
                                      (timeChange)="onTimeUpdated2($event)"></app-time-converter>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="RegularWorktimeLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('regular_worktime')?.value"
                                      (timeChange)="onTimeUpdated3($event)"></app-time-converter>
                  <app-time-converter [label]="Overtime2AmountLabel"
                                      [timeInMinutes]="overtimeRuleForm.get('overtime2_amount')?.value"
                                      (timeChange)="onTimeUpdated4($event)"></app-time-converter>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div>&nbsp;</div>
    <div mat-dialog-actions style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <button mat-raised-button (click)="saveOvertimeRule();" [disabled]="!writeRight || !overtimeRuleForm.dirty" color="primary">{{'common.Save' | translate}}</button>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
      <div class="record-count">
        {{'common.Record' | translate}} {{currentIndex + 1}} / {{overtimeRules.length}}
      </div>
    </div>
  </div>
</form>
















