import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { WtGroup } from '../models/wtgroup.model';

@Injectable()
export class WtGroupService {
  private wtgroupUrl: string = 'api/worktimegroups';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getwtgroups(query: string): Observable<WtGroup[]> {
    let endpointUrl = `${this.wtgroupUrl}/${query}`;
    return this.http.get<WtGroup[]>(endpointUrl).pipe();
  }

  updateWtGroup(wtgroup): Observable<WtGroup> {
    return this.http.put<WtGroup>(this.wtgroupUrl, wtgroup);
  }

  newWtGroup(wtgroup): Observable<WtGroup> {
    return this.http.post<WtGroup>(this.wtgroupUrl, wtgroup);
  }

  deleteWtGroup(id: number) {
    let endpointUrl = `${this.wtgroupUrl}/${id}`;
    return this.http.delete<WtGroup>(endpointUrl);
  }

}

