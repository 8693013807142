<div class="page-content">
    <h3>{{tableText}}</h3>
    <div fxLayout="row" fxLayoutGap="50px">
      <mat-form-field style="width: 50%;">
        <input matInput (keyup)="onFilterTextChanged($event.target.value)" placeholder="{{'common.FilterByDescr' | translate}}">
      </mat-form-field>
      <button mat-raised-button color="primary" class="custom-button" [disabled]="!writeRight" (click)="createBaseTable()">{{newTableText}}</button>
    </div>

    <div class="mat-elevation-z8" [hidden]="numberOfRows == 0">
      <mat-table [dataSource]="dataSource"
                 matSort (matSortChange)="loadData()"
                 matSortActive="{{defaultSortColumn}}" matSortDirection="{{defaultSortOrder}}">
        <ng-container *ngIf="tableNumber != 7 && tableNumber != 3" matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Number' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.number}} </mat-cell>
        </ng-container>
        <ng-container *ngIf="tableNumber==3" matColumnDef="numberStr">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Number' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.numberStr}} </mat-cell>
        </ng-container>
        <ng-container *ngIf="tableNumber==7" matColumnDef="tasknumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tabasetable.JobTasks' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.tasknumber}} </mat-cell>
        </ng-container>
        <ng-container *ngIf="tableNumber==5" matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Title' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
        </ng-container>
        <ng-container *ngIf="tableNumber==5" matColumnDef="infotext">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Infotext' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.infotext}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Descr' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
        </ng-container>
        <ng-container *ngIf="tableNumber==3" matColumnDef="extraDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tabasetable.CloseDate' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.extraDate | date: 'dd.MM.yyyy'}} </mat-cell>
        </ng-container>
        <ng-container *ngIf="tableNumber==4" matColumnDef="extraNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'tabasetable.ExtCalcDaytpe' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.extraNumber}} </mat-cell>
        </ng-container>
        <ng-container *ngIf="tableNumber==8" matColumnDef="extraNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'wtgroup.Passivated' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.extraNumber}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef fxFlex="120px">
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="120px" fxFlexOffset="30px">
            <button mat-icon-button matTooltip="{{'common.Delete' | translate}}" [disabled]="!writeRight" (click)="$event.stopPropagation();confirmDelete(element)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"
                 (click)="editBaseTable(row)">
        </mat-row>
      </mat-table>
    </div>

    <mat-paginator [hidden]="numberOfRows < 11"
                   (page)="getData($event)"
                   [pageSize]="10"
                   [pageSizeOptions]="[10, 20, 50]"
                   showFirstLastButtons></mat-paginator>

</div>









