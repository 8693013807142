import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaControlComponent } from './tacontrol.component';
import { WtGroupComponent } from './wtgroup/wtgroup.component';
import { BalanceCutComponent } from './balancecut/balancecut.component';
import { BalanceCalcComponent } from './balancecalc/balancecalc.component';
import { OvertimeRuleComponent } from './overtimerule/overtimerule.component';
import { AuthService } from '../services/auth.service';
import { AuthGuard } from '../services/auth-guard.service';

const taControlRoutes: Routes = [
  {
    path: 'tacontrol', component: TaControlComponent,
    children: [
      { path: 'wtgroup', component: WtGroupComponent, canActivate: [AuthGuard] },
      { path: 'balancecut', component: BalanceCutComponent, canActivate: [AuthGuard] },
      { path: 'balancecalc', component: BalanceCalcComponent, canActivate: [AuthGuard] },
      { path: 'overtimerule', component: OvertimeRuleComponent, canActivate: [AuthGuard] },
      //{ path: 'tabasetable4', component: TaBasetableComponent, canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(taControlRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthService, AuthGuard
  ]
})
export class TaControlRoutingModule { }
