export class BalanceCut {
  balance_cut_id: number;
  descr: string;
  daily_neglimit: number;
  daily_poslimit: number;
  prd_duration: number;
  prd_firstday: Date;
  prd_neglimit: number;
  prd_poslimit: number;
  salprd_day: number;
  salprd_neglim: number;
  salprd_poslim: number;
  daily_alarmneglimit: number;
  daily_alarmposlimit: number;
  passivated: boolean;
  periodNormalBalanceTime: number;
  periodNormalLength: number;
  periodNormalStartDate: Date;

}
