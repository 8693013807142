import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { SharedMaterialModule } from '../shared-mat.module';
import { TaControlRoutingModule } from './tacontrol-routing.module';
import { TaControlComponent } from './tacontrol.component';
import { WtGroupComponent } from './wtgroup/wtgroup.component';
import { BalanceCutComponent } from './balancecut/balancecut.component';
import { OvertimeRuleComponent } from './overtimerule/overtimerule.component';
import { BalanceCalcComponent } from './balancecalc/balancecalc.component';
import { CalendarRuleService } from '../services/calendarrule.service';
import { BalCalcRuleService } from '../services/balcalcrule.service';
import { OverTimeRuleService } from '../services/overtimerule.service';
import { SalPeriodGroupService } from '../services/salprdgrp.service';
import { BalanceCutService } from '../services/balancecut.service';
import { SaldoNumberService } from '../services/saldonumber.service';
import { SaldoRelationService } from '../services/saldorelation.service';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    SharedModule,
    SharedMaterialModule,
    TaControlRoutingModule
    //CommonModule,
    //MatDialogModule,
    //MatFormFieldModule,
    //MatInputModule      
  ],
  declarations: [
    TaControlComponent,
    WtGroupComponent,
    BalanceCutComponent,
    BalanceCalcComponent,
    OvertimeRuleComponent
    //TabasetableDialogComponent,
    //AbsenceCodeEditorComponent,
    //WorkNumberEditorComponent,
    //TaBasetableComponent
  ],
  providers: [
    CalendarRuleService,
    BalCalcRuleService,
    OverTimeRuleService,
    SalPeriodGroupService,
    BalanceCutService,
    SaldoNumberService,
    SaldoRelationService,
    //AcceptLevelService,
    //SaldoTypeService,
    //CalcRuleService,
    //WorkNumberService,
    //AbsenceCodeService
/*    TaBasetableService*/
  ]
})
export class TaControlModule {
}
