import { Component } from '@angular/core';

@Component({
  selector: 'app-tacontrol',
  templateUrl: './tacontrol.component.html',
  styleUrls: ['./tacontrol.component.scss']
})
export class TaControlComponent {

}
