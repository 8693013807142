import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SaldoRelation } from '../models/saldorelation.model';
import { Observable } from 'rxjs';

@Injectable()

export class SaldoRelationService {
  private saldorelationUrl: string = 'api/saldorelations';

  constructor(
    private http: HttpClient) { }

  getSaldoRelations(calc_rule_id:number): Observable<SaldoRelation[]> {
    let endpointUrl = `${this.saldorelationUrl}/${calc_rule_id}`;
    return this.http.get<SaldoRelation[]>(endpointUrl);
  }

  addSaldoRelation(saldoRelation): Observable<SaldoRelation> {
    return this.http.post<SaldoRelation>(this.saldorelationUrl, saldoRelation);
  }

  updateSaldoRelation(saldoRelation): Observable<SaldoRelation> {
    return this.http.put<SaldoRelation>(this.saldorelationUrl, saldoRelation);
  }

  deleteSaldoRelation(calc_rule_id: number, saldonumber: number) {
    const params = {
      CalcRule: calc_rule_id,
      Saldonumber: saldonumber
    };
    return this.http.delete(this.saldorelationUrl, { params, responseType: 'text' });
  }
  
}
