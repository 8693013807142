
import { Component, OnDestroy, Input, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Daytype } from '../../models/daytype.model';
import { AbsenceCode } from '../../models/absencecode.model';
import { AcceptLevel } from '../../models/acceptlevel.model';
import { SaldoNumber } from '../../models/saldonumber.model';
import { CalcRule } from '../../models/calcrule.model';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AbsenceCodeService } from '../../services/absencecode.service';
import { AcceptLevelService } from '../../services/acceptlevel.service';
import { SaldoNumberService } from "../../services/saldonumber.service";
import { CalcRuleService } from "../../services/calcrule.service";


@Component({
  selector: 'app-absencecode-editor',
  templateUrl: './absencecode-editor.component.html',
  styleUrls: ['./absencecode-editor.component.scss']
})
export class AbsenceCodeEditorComponent implements OnChanges {
  @Input() tableData: AbsenceCode = new AbsenceCode();
  @Input() tableNumber: number;
  @ViewChild('form', { static: true }) form: NgForm;
  public isFormDirty$ = new Subject<boolean>();
  acceptlevels: AcceptLevel[] = [];
  saldotypes: SaldoNumber[] = [];
  calcrules: CalcRule[] = [];
  absencecodes: AbsenceCode[] = [];
  absencecodesvac: AbsenceCode[] = [];
  reasoncodeType: number;
  
  public isNewTable = false;
  private onTableSaved = new Subject<any>();
  public extraText: string;

  reaconcodetypes: Daytype[] = [
    { itemNumber: 1, description: this.translationService.getTranslation('AbsenceCode.Cumulative') },
    { itemNumber: 2, description: this.translationService.getTranslation('AbsenceCode.InsideCode') },
    { itemNumber: 3, description: this.translationService.getTranslation('AbsenceCode.InquiryCode') },
    { itemNumber: 4, description: this.translationService.getTranslation('AbsenceCode.DayScheduleSelectionCode') },
    { itemNumber: 5, description: this.translationService.getTranslation('AbsenceCode.WorkorderCode') },
    { itemNumber: 6, description: this.translationService.getTranslation('AbsenceCode.ConditionExtraCode') },
    { itemNumber: 7, description: this.translationService.getTranslation('AbsenceCode.OvertimeCode') },
    { itemNumber: 8, description: this.translationService.getTranslation('AbsenceCode.ExtendedWorktimeCode') },
    { itemNumber: 9, description: this.translationService.getTranslation('AbsenceCode.LunchCode') },
    { itemNumber: 10, description: this.translationService.getTranslation('AbsenceCode.WorkDuringLunchcode') },
    { itemNumber: 11, description: this.translationService.getTranslation('AbsenceCode.ShiftedWorkCode') },
    { itemNumber: 12, description: this.translationService.getTranslation('AbsenceCode.PreClockingRemoverCode') },
    { itemNumber: 13, description: this.translationService.getTranslation('AbsenceCode.SpecialCode') },
    { itemNumber: 14, description: this.translationService.getTranslation('AbsenceCode.SalaryAnnouncement') },
    { itemNumber: 15, description: this.translationService.getTranslation('AbsenceCode.CostplaceChangeCode') },
    { itemNumber: 16, description: this.translationService.getTranslation('AbsenceCode.WorkOrderEndCode') },
    { itemNumber: 17, description: this.translationService.getTranslation('AbsenceCode.ConditionExtraEndCode') },
    { itemNumber: 23, description: this.translationService.getTranslation('AbsenceCode.VacationCode') },
    { itemNumber: 24, description: this.translationService.getTranslation('AbsenceCode.WTExtraCode') }
  ];

  @Input() createNew: boolean = false;
  @Input() isEditMode = true;

  absenceCodeForm: FormGroup;
  tableSaved$ = this.onTableSaved.asObservable();

  ngAfterViewInit() {
    if (this.absenceCodeForm) {
      // Subscribe to changes in the form's values or status
      this.absenceCodeForm.valueChanges.subscribe(() => {
        this.updateFormDirtyStatus();
      });
    }
  }

  updateFormDirtyStatus() {
    const formIsDirty = this.absenceCodeForm.dirty;
    this.isFormDirty$.next(formIsDirty); // Emit the dirty state
    this.cdr.detectChanges();
    console.log('Form dirty status:', formIsDirty);
  }

  get tablenumber() {
    return this.absenceCodeForm.get('tablenumber');
  }
  get number() {
    return this.absenceCodeForm.get('number');
  }
  //get reasoncode_type() {
  //  return this.absenceCodeForm.get('reasoncode_type');
  //}
  //get infotext() {
  //  return this.absenceCodeForm.get('infotext');
  //}
  //get title() {
  //  return this.absenceCodeForm.get('title');
  //}
  //get description() {
  //  return this.absenceCodeForm.get('description');
  //}

  //get infoReasoncode() {
  //  return this.absenceCodeForm.get('infoReasoncode');
  //}
  
  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private absenceCodeService: AbsenceCodeService,
    private translationService: AppTranslationService,
    private acceptLevelService: AcceptLevelService,
    private saltotypeService: SaldoNumberService,
    private calcruleService: CalcRuleService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.buildForm();
  }

  ngOnInit() {
    //this.extraText = this.translationService.getTranslation('tabasetable.CloseDate'); '';
    this.acceptLevelService.getAcceptLevels()
      .subscribe(data => {
        this.acceptlevels = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.saltotypeService.getsaldonumbers(4)
      .subscribe(data => {
        this.saldotypes = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.calcruleService.getcalcrules()
      .subscribe(data => {
        this.calcrules = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.absenceCodeService.getSpecificAbsenceCode(1)
      .subscribe(data => {
        this.absencecodes = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.absenceCodeService.getSpecificAbsenceCode(2)
      .subscribe(data => {
        this.absencecodesvac = data;
      },
        (error: any) => {
          //console.log(error);
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('TableData received in TaBasetableEditorComponent:', changes);
    if (changes['tableData']) {
      console.log('Previous:', changes['tableData'].previousValue);
      console.log('Current:', changes['tableData'].currentValue);
    }
    if (this.tableData) {
      this.isNewTable = false;
      this.resetForm(); // Ensure form is reset with the current tableData
    }
  }

  updateTableData(changes: { previousValue: any; currentValue: any; firstChange: boolean }) {
    console.log('Manually triggered updateTableData:', changes);
    if (changes.currentValue) {
      this.isNewTable = this.createNew;;
      this.resetForm(); // Reset the form with the new tableData
    }
  }

  public save() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.absenceCodeForm.valid) {
      /*      this.alertService.showValidationError();*/
      return;
    }

    var editedAbsCodetable = this.getEditedAbsCodetable();
    editedAbsCodetable.number = Number(editedAbsCodetable.number);
    editedAbsCodetable.tableNumber = Number(editedAbsCodetable.tableNumber);

    if (this.isNewTable) {
      this.absenceCodeService.newAbsenceCode(editedAbsCodetable)
        .subscribe(response => this.saveSuccessHelper(0, response),
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    else {
      this.absenceCodeService.updateAbsenceCode(editedAbsCodetable).subscribe(
        response => this.saveSuccessHelper(1, response),
        error => {
          if (error == "Unauthorized")
            this.authService.logout();
          else
            this.saveFailedHelper(error);
        });
    }
  }

  private getEditedAbsCodetable(): AbsenceCode {
    const formModel = this.absenceCodeForm.value;

    return {
      number: formModel.number,
      description: formModel.description,
      tableNumber: formModel.tablenumber,
      infotext: formModel.infotext,
      infoReasoncode: formModel.infoReasoncode,
      reasoncode_type: formModel.reasoncode_type,
      long_term: formModel.long_term,
      no_eff_on_info: formModel.no_eff_on_info,
      advance_booking: formModel.advance_booking,
      no_ct600_returntime: formModel.no_ct600_returntime,
      not_allowed: formModel.Not_allowed,
      extended_calctm: formModel.extended_calctm,
      not_allowed_in_client: formModel.Not_allowed_in_client,
      acc_to_flex_saldo: formModel.acc_to_flex_saldo,
      acc_to_def_job: formModel.acc_to_def_job,
      acc_to_job: formModel.acc_to_job,
      acc_to_circ_add: formModel.acc_to_circ_add,
      acc_to_worktime: formModel.acc_to_worktime,
      no_eff_on_overtime: formModel.no_eff_on_overtime,
      hide_worktime: formModel.hide_worktime,
      overtimeAsVacation: formModel.overtimeAsVacation,
      accept_level: formModel.acceptlevel,
      acc_adds_to_saldo: formModel.acc_adds_to_saldo,
      acc_decs_from_saldo: formModel.acc_decs_from_saldo,
      calc_rule_id: formModel.calc_rule_id,
      norm_work: formModel.norm_work,
      transfer_system1: formModel.transfer_system1,
      transfer_system2: formModel.transfer_system2,
      salarytype_id: formModel.salarytype_id,
      extra_reasoncode_id: formModel.extra_reasoncode_id,
      vac_sat_reasoncode_id: formModel.vac_sat_reasoncode_id,
      vac_sun_reasoncode_id: formModel.vac_sun_reasoncode_id,
      withExtraData: 1,
      title: formModel.title
    };
  }

  private saveSuccessHelper(eType: number, mess: string) {
    const response = {
      message: eType === 0
        ? this.translationService.getTranslation('notifications.InsertRecordSucceeded')
        : this.translationService.getTranslation('notifications.UpdateRecordSucceeded'),
      tableNumber: this.tableData.tableNumber
    };
    this.alertService.successMessage(response.message);
    this.onTableSaved.next(response);
  }

  private saveFailedHelper(mess: string) {
    this.alertService.errorMessage(mess);
    //this.onTableSaved.next(mess);
  }

  private cancel() {
    this.resetForm();

    //this.alertService.resetStickyMessage();
  }

  private buildForm() {
    this.absenceCodeForm = this.formBuilder.group({
      tablenumber: [''],
      number: ['', Validators.required],
      description: ['', Validators.required],
      title: ['', Validators.required],
      infotext: ['', Validators.required],
      reasoncode_type: ['', Validators.required],
      infoReasoncode: null,
      long_term: false,
      no_eff_on_info: false,
      advance_booking: false,
      no_ct600_returntime: false,
      Not_allowed: false,
      extended_calctm: false,
      Not_allowed_in_client: false,
      acc_to_flex_saldo: false,
      acc_to_def_job: false,
      acc_to_job: false,
      acc_to_circ_add: false,
      acc_to_worktime: false,
      no_eff_on_overtime: false,
      hide_worktime: false,
      overtimeAsVacation: false,
      acceptlevel: null,
      acc_adds_to_saldo: null,
      acc_decs_from_saldo: null,
      calc_rule_id: null,
      extra_reasoncode_id: null,
      vac_sat_reasoncode_id: null,
      vac_sun_reasoncode_id: null,
      norm_work: false,
      transfer_system1: false,
      transfer_system2: false,
      salarytype_id: null
    })
  }

  private resetForm() {
    if (!this.tableData) {
      this.isNewTable = true;
      this.tableData = new AbsenceCode();
    }
    this.reasoncodeType = this.tableData.reasoncode_type;
    this.absenceCodeForm.reset({
      tablenumber: this.tableNumber,
      number: this.tableData.number || '',
      description: this.tableData.description,
      title: this.tableData.title || null,
      infotext: this.tableData.infotext || null,
      reasoncode_type: this.tableData.reasoncode_type || '',
      infoReasoncode: this.tableData.infoReasoncode || null,
      long_term: this.tableData.long_term || false,
      no_eff_on_info: this.tableData.no_eff_on_info || false,
      advance_booking: this.tableData.advance_booking || false,
      no_ct600_returntime: this.tableData.no_ct600_returntime || false,
      Not_allowed: this.tableData.not_allowed || false,
      extended_calctm: this.tableData.extended_calctm || false,
      Not_allowed_in_client: this.tableData.not_allowed_in_client || false,
      acc_to_flex_saldo: this.tableData.acc_to_flex_saldo || false,
      acc_to_def_job: this.tableData.acc_to_def_job || false,
      acc_to_job: this.tableData.acc_to_job || false,
      acc_to_circ_add: this.tableData.acc_to_circ_add || false,
      acc_to_worktime: this.tableData.acc_to_worktime || false,
      no_eff_on_overtime: this.tableData.no_eff_on_overtime || false,
      hide_worktime: this.tableData.hide_worktime || false,
      overtimeAsVacation: this.tableData.overtimeAsVacation || false,
      acceptlevel: this.tableData.accept_level || null,
      acc_adds_to_saldo: this.tableData.acc_adds_to_saldo ||null,
      acc_decs_from_saldo: this.tableData.acc_decs_from_saldo || null,
      calc_rule_id: this.tableData.calc_rule_id || null,
      extra_reasoncode_id: this.tableData.extra_reasoncode_id || null,
      vac_sat_reasoncode_id: this.tableData.vac_sat_reasoncode_id || null,
      vac_sun_reasoncode_id: this.tableData.vac_sun_reasoncode_id || null,
      norm_work: this.tableData.norm_work || false,
      transfer_system1: this.tableData.transfer_system1 || false,
      transfer_system2: this.tableData.transfer_system2 || false,
      salarytype_id: this.tableData.salarytype_id || null
    });
  }


  get canManageTaDataa() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}

