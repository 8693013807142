import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BalCalcRule } from '../models/balcalcrule.model';

@Injectable()
export class BalCalcRuleService {
  private calcruleUrl: string = 'api/balcalcrules';
  private calcrelationruleUrl: string = 'api/balcalcrulerelations';
  

  constructor(
    private http: HttpClient) { }

  getbalcalcrules(query: string): Observable<BalCalcRule[]> {
    let endpointUrl = `${this.calcruleUrl}/${query}`;
    return this.http.get<BalCalcRule[]>(endpointUrl);
  }

  getbalcalcrulerelations(query: string): Observable<BalCalcRule[]> {
    let endpointUrl = `${this.calcrelationruleUrl}/${query}`;
    return this.http.get<BalCalcRule[]>(endpointUrl);
  }

  updateBalanceCalc(balancecalc): Observable<BalCalcRule> {
    return this.http.put<BalCalcRule>(this.calcruleUrl, balancecalc);
  }

  newBalanceCalc(balancecalc): Observable<BalCalcRule> {
    return this.http.post<BalCalcRule>(this.calcruleUrl, balancecalc);
  }

  deleteBalanceCalc(id: number) {
    let endpointUrl = `${this.calcruleUrl}/${id}`;
    return this.http.delete<BalCalcRule>(endpointUrl);
  }

}

