<form (keydown.enter)="loadData()">
  <div class="page-content">
    <h3>{{'menu.BalanceCalc' | translate}}</h3>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex.gt-sm="40%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <mat-form-field fxFlex="grow" [formGroup]="searchForm">
          <input matInput formControlName="searchName" placeholder="{{'common.FilterByNumberOrDescr' | translate}}">
          <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchForm.get('searchName').value" (click)="clearSearchField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="isNewBalanceCalc" class="custom-button" (click)="loadData()">{{'common.Refresh' | translate}}</button>
      </div>
      <div fxFlex.gt-sm="60%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <button mat-raised-button (click)="showFirstRecord()" [disabled]="isNewBalanceCalc || (currentIndex === 0)"><span class="material-icons">first_page</span></button>
        <button mat-raised-button (click)="showPreviousRecord()" [disabled]="isNewBalanceCalc || (currentIndex === 0)"><span class="material-icons">navigate_before</span></button>
        <button mat-raised-button (click)="showNextRecord()" [disabled]="isNewBalanceCalc || (currentIndex === balanceCalcs.length - 1)"><span class="material-icons">navigate_nextr</span></button>
        <button mat-raised-button (click)="showLastRecord()" [disabled]="isNewBalanceCalc || (currentIndex === balanceCalcs.length - 1)"><span class="material-icons">last_page</span></button>
        <button mat-raised-button color="primary" [disabled]="!writeRight" (click)="createBalanceCut()">{{'balancecalc.NewBalanceCalc' | translate}}</button>
        <button mat-raised-button color="warn" [disabled]="!writeRight || isNewBalanceCalc" (click)="confirmDelete(balanceCalc)">{{'balancecalc.DeleteBalanceCalc' | translate}}</button>
      </div>
    </div>
    <div [formGroup]="balanceCalcForm">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px">
        <div fxFlex.gt-sm="50%">
          <div fxLayout="column" fxLayoutGap="2px">
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="80px">
                <mat-label>{{'common.Number' | translate}}</mat-label>
                <input matInput max="32767" formControlName="calc_rule_id" [readonly]="!isNewBalanceCalc" [attr.tabindex]="!isNewBalanceCalc ? -1 : null" />
              </mat-form-field>
              <mat-form-field [floatLabel]="floatLabels" fxFlex="250px">
                <mat-label>{{'common.Descr' | translate}}</mat-label>
                <input matInput formControlName="descr" maxlength="40" />
              </mat-form-field>
              <div fxFlex="20px"></div> <!-- Spacer for additional spacing -->
              <mat-slide-toggle formControlName="passivated">{{'wtgroup.Passivated' | translate}}</mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <button mat-raised-button (click)="addRow()" [disabled]="!writeRight || isNewBalanceCalc" color="primary" style="float: right;">{{'common.AddRow' | translate}}</button>
    </div>
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
        <th mat-header-cell *matHeaderCellDef [ngSwitch]="col.key">
          <span *ngSwitchDefault>{{ col.label }}</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
            <div class="btn-edit" *ngSwitchCase="'isEdit'">
              <button mat-button [disabled]="!writeRight" (click)="element.isEdit = !element.isEdit">
                {{'common.Change' | translate}}
              </button>
              <button mat-button [disabled]="!writeRight" class="button-remove" (click)="confirmDeleteSaldoRelation(element)">
                {{'common.Delete' | translate}}
              </button>
            </div>
            <span *ngSwitchDefault>
              {{ element[col.key] }}
            </span>
          </div>
          <div *ngIf="element.isEdit">
            <div [ngSwitch]="col.key">
              <div class="btn-edit" *ngSwitchCase="'isEdit'">
                <button mat-button (click)="element.isEdit = !element.isEdit">
                  {{'common.Cancel' | translate}}
                </button>
                <button mat-button (click)="editRow(element)" [disabled]="disableSubmit(element.id)">
                  {{'common.Edit' | translate}}
                </button>
              </div>
              <div *ngSwitchCase="'saldonumber_id'">
                <mat-form-field appearance="fill">
                  <mat-select [(ngModel)]="element[col.key]"  [ngModelOptions]="{standalone: true}" required>
                    <mat-option *ngFor="let salRow of saldoNumbers" [value]="salRow.saldonumber_id">
                      {{ salRow.saldonumber_id }} {{ salRow.descr }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngSwitchCase="'balance_cut_id'">
                <mat-form-field appearance="fill">
                  <mat-select [(ngModel)]="element[col.key]"  [ngModelOptions]="{standalone: true}" required>
                    <mat-option *ngFor="let relRow of balanceCuts" [value]="relRow.balance_cut_id">
                      {{ relRow.balance_cut_id }} {{ relRow.descr }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- Default case for other keys -->
              <div *ngSwitchDefault>
                <mat-form-field class="form-input">
                  <input matInput
                         [type]="col.type || 'text'"
                         [required]="col.required"
                         [pattern]="col.pattern"
                         [(ngModel)]="element[col.key]"
                         (change)="inputHandler($event, element.id, col.key)" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div>&nbsp;</div>
    <div mat-dialog-actions style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <button mat-raised-button (click)="saveBalanceCalc();" [disabled]="!writeRight || !balanceCalcForm.dirty" color="primary">{{'common.Save' | translate}}</button>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
      <div class="record-count">
        {{'common.Record' | translate}} {{currentIndex + 1}} / {{balanceCalcs.length}}
      </div>
    </div>
  </div>
</form>
















