<form #form="ngForm" [formGroup]="taBasetableForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">
  <mat-form-field class="invisible example-full-width">
    <input matInput formControlName="tablenumber" [readonly]="true" />
  </mat-form-field>
  <div *ngIf="tablenumber.value != 3" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="150px">
      <mat-label>{{'common.Number' | translate}}</mat-label>
      <input matInput max="32767" formControlName="number" [readonly]="!isNewTable" [attr.tabindex]="!isNewTable ? -1 : null" />
    </mat-form-field>
  </div>
  <div *ngIf="tablenumber.value == 3" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="150px">
      <mat-label>{{'common.Number' | translate}}</mat-label>
      <input matInput formControlName="numberStr" [readonly]="!isNewTable" [attr.tabindex]="!isNewTable ? -1 : null" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
      <mat-label>{{'common.Descr' | translate}}</mat-label>
      <input matInput formControlName="description" maxlength="40" />
    </mat-form-field>
    <mat-error *ngIf="description.hasError('required')">
      {{'notifications.FieldRequired' | translate}}
    </mat-error>
  </div>
  <div *ngIf="tablenumber.value == 3" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
      <mat-label>{{extraText}}</mat-label>
      <input matInput formControlName="extraDate" [matDatepicker]="picker1" (click)="picker1.open()" />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
  </div>
  <div *ngIf="tablenumber.value == 4" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
      <mat-label>{{'tabasetable.ExtCalcDaytpe'|translate}}</mat-label>
      <mat-select id="extraNumber" formControlName="extraNumber">
        <mat-option [value]=null></mat-option>
        <mat-option *ngFor="let daytype of daytypes" [value]="daytype.itemNumber">
          {{daytype.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="tablenumber.value == 8" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
      <mat-label>{{'wtgroup.Passivated'|translate}}</mat-label>
      <input matInput
             formControlName="extraNumber"
             type="number"
             min="0"
             max="1"
             step="1"
             (keypress)="validateNumberInput($event)" />
    </mat-form-field>
  </div>
  <div>&nbsp;</div>
</form>

