<form (keydown.enter)="loadData()">
  <div class="page-content">
    <h3>{{'menu.BalanceCut' | translate}}</h3>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex.gt-sm="40%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <mat-form-field fxFlex="grow" [formGroup]="searchForm">
          <input matInput formControlName="searchName" placeholder="{{'common.FilterByNumberOrDescr' | translate}}">
          <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchForm.get('searchName').value" (click)="clearSearchField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="isNewBalanceCut" class="custom-button" (click)="loadData()">{{'common.Refresh' | translate}}</button>
      </div>
      <div fxFlex.gt-sm="60%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <button mat-raised-button (click)="showFirstRecord()" [disabled]="isNewBalanceCut || (currentIndex === 0)"><span class="material-icons">first_page</span></button>
        <button mat-raised-button (click)="showPreviousRecord()" [disabled]="isNewBalanceCut || (currentIndex === 0)"><span class="material-icons">navigate_before</span></button>
        <button mat-raised-button (click)="showNextRecord()" [disabled]="isNewBalanceCut || (currentIndex === balanceCuts.length - 1)"><span class="material-icons">navigate_nextr</span></button>
        <button mat-raised-button (click)="showLastRecord()" [disabled]="isNewBalanceCut || (currentIndex === balanceCuts.length - 1)"><span class="material-icons">last_page</span></button>
        <button mat-raised-button color="primary" [disabled]="!writeRight" (click)="createBalanceCut()">{{'balancecut.NewBalanceCut' | translate}}</button>
        <button mat-raised-button color="warn" [disabled]="!writeRight || isNewBalanceCut" (click)="confirmDelete(balanceCut)">{{'balancecut.DeleteBalanceCut' | translate}}</button>
      </div>
    </div>
    <div [formGroup]="balanceCutForm">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px">
        <div fxFlex.gt-sm="50%">
          <div fxLayout="column" fxLayoutGap="2px">
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="80px">
                <mat-label>{{'common.Number' | translate}}</mat-label>
                <input matInput max="32767" formControlName="balance_cut_id" [readonly]="!isNewBalanceCut" [attr.tabindex]="!isNewBalanceCut ? -1 : null" />
              </mat-form-field>
              <mat-form-field [floatLabel]="floatLabels" fxFlex="250px">
                <mat-label>{{'common.Descr' | translate}}</mat-label>
                <input matInput formControlName="descr" maxlength="40" />
              </mat-form-field>
              <div fxFlex="20px"></div> <!-- Spacer for additional spacing -->
              <mat-slide-toggle formControlName="passivated">{{'wtgroup.Passivated' | translate}}</mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <mat-grid-list cols="2" gutterSize="20px" rowHeight="450px">
        <mat-grid-tile>
          <div class="column-container">
            <mat-card>
              <mat-card-header>
                <mat-card-title> {{'balancecut.Daily' | translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="negativeCutLabel"
                                      [timeInMinutes]="balanceCutForm.get('daily_neglimit')?.value"
                                      (timeChange)="onTimeUpdated1($event)"></app-time-converter>
                  <app-time-converter [label]="positiveCutLabel"
                                      [timeInMinutes]="balanceCutForm.get('daily_poslimit')?.value"
                                      (timeChange)="onTimeUpdated2($event)"></app-time-converter>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="negativeAlarmLabel"
                                      [timeInMinutes]="balanceCutForm.get('daily_alarmneglimit')?.value"
                                      (timeChange)="onTimeUpdated3($event)"></app-time-converter>
                  <app-time-converter [label]="positiveAlarmLabel"
                                      [timeInMinutes]="balanceCutForm.get('daily_alarmposlimit')?.value"
                                      (timeChange)="onTimeUpdated4($event)"></app-time-converter>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card>
              <mat-card-header>
                <mat-card-title> {{'balancecut.Periodically' | translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <mat-form-field [floatLabel]="floatLabels" fxFlex="250px">
                    <mat-label>{{'common.Length' | translate}}</mat-label>
                    <input matInput formControlName="prd_duration" />
                  </mat-form-field>
                  <mat-form-field [floatLabel]="floatLabels" fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
                    <mat-label>{{'balancecut.FirstDay' | translate}}</mat-label>
                    <input matInput formControlName="prd_firstday" [matDatepicker]="picker1" (click)="picker1.open()" />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="negativeCutLabel"
                                      [timeInMinutes]="balanceCutForm.get('prd_neglimit')?.value"
                                      (timeChange)="onTimeUpdated5($event)"></app-time-converter>
                  <app-time-converter [label]="positiveCutLabel"
                                      [timeInMinutes]="balanceCutForm.get('prd_poslimit')?.value"
                                      (timeChange)="onTimeUpdated6($event)"></app-time-converter>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="column-container">
            <mat-card>
              <mat-card-header>
                <mat-card-title> {{'balancecut.SalaryPeriodically' | translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <mat-form-field [floatLabel]="floatLabels" fxFlex="250px">
                    <mat-label>{{'balancecut.PeriodDay' | translate}}</mat-label>
                    <input matInput formControlName="salprd_day" />
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="negativeAlarmLabel"
                                      [timeInMinutes]="balanceCutForm.get('salprd_neglim')?.value"
                                      (timeChange)="onTimeUpdated7($event)"></app-time-converter>
                  <app-time-converter [label]="positiveAlarmLabel"
                                      [timeInMinutes]="balanceCutForm.get('salprd_poslim')?.value"
                                      (timeChange)="onTimeUpdated8($event)"></app-time-converter>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card>
              <mat-card-header>
                <mat-card-title> {{'balancecut.SalaryPeriodicallyAlignment' | translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <mat-form-field [floatLabel]="floatLabels" fxFlex="250px">
                    <mat-label>{{'common.Length' | translate}}</mat-label>
                    <input matInput formControlName="periodNormalLength" />
                  </mat-form-field>
                  <mat-form-field [floatLabel]="floatLabels" fxFlex="250px">
                    <mat-label>{{'balancecut.FirstDay' | translate}}</mat-label>
                    <input matInput formControlName="periodNormalStartDate" [matDatepicker]="picker2" (click)="picker2.open()" />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <app-time-converter [label]="normalBalanceTimeLabel"
                                      [timeInMinutes]="balanceCutForm.get('periodNormalBalanceTime')?.value"
                                      (timeChange)="onTimeUpdated9($event)"></app-time-converter>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div>&nbsp;</div>
    <div mat-dialog-actions style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <button mat-raised-button (click)="saveBalanceCut();" [disabled]="!writeRight || !balanceCutForm.dirty" color="primary">{{'common.Save' | translate}}</button>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
      <div class="record-count">
        {{'common.Record' | translate}} {{currentIndex + 1}} / {{balanceCuts.length}}
      </div>
    </div>
  </div>
</form>
















