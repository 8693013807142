export class SaldoRelation {
  id: number;
  calc_rule_id: number;
  saldonumber_id: number;
  saldonumber_descr: string;
  balance_cut_id: number;
  balance_cut_descr: string;
  isEdit: boolean;
}

export const SaldoRelationColumns = [
  {
    key: 'saldonumber_id',
    type: 'select'    
  },
  {
    key: 'saldonumber_descr',
    type: 'text'
  },
  {
    key: 'balance_cut_id',
    type: 'select'
  },
  {
    key: 'balance_cut_descr',
    type: 'text'
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  }
];


