<form (keydown.enter)="loadData()">
  <div class="page-content">
    <h3>{{'menu.TAGroup' | translate}}</h3>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex.gt-sm="40%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <mat-form-field fxFlex="grow" [formGroup]="searchForm">
          <input matInput formControlName="searchName" placeholder="{{'common.FilterByNumberOrDescr' | translate}}">
          <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchForm.get('searchName').value" (click)="clearSearchField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="isNewWtGroup" class="custom-button" (click)="loadData()">{{'common.Refresh' | translate}}</button>
      </div>
      <div fxFlex.gt-sm="60%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <button mat-raised-button (click)="showFirstRecord()" [disabled]="isNewWtGroup || (currentIndex === 0)"><span class="material-icons">first_page</span></button>
        <button mat-raised-button (click)="showPreviousRecord()" [disabled]="isNewWtGroup || (currentIndex === 0)"><span class="material-icons">navigate_before</span></button>
        <button mat-raised-button (click)="showNextRecord()" [disabled]="isNewWtGroup || (currentIndex === wtGroups.length - 1)"><span class="material-icons">navigate_nextr</span></button>
        <button mat-raised-button (click)="showLastRecord()" [disabled]="isNewWtGroup || (currentIndex === wtGroups.length - 1)"><span class="material-icons">last_page</span></button>
        <button mat-raised-button color="primary" [disabled]="!writeRight" (click)="createWtGroup()">{{'wtgroup.NewWtGroup' | translate}}</button>
        <button mat-raised-button color="warn" [disabled]="!writeRight || isNewWtGroup" (click)="confirmDelete(wtGroup)">{{'wtgroup.DeleteWtGroup' | translate}}</button>
      </div>
    </div>
    <div [formGroup]="wtGroupForm">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px">
        <div fxFlex.gt-sm="50%">
          <div fxLayout="column" fxLayoutGap="2px">
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="80px">
                <mat-label>{{'common.Number' | translate}}</mat-label>
                <input matInput max="32767" formControlName="wh_group_id" [readonly]="!isNewWtGroup" [attr.tabindex]="!isNewWtGroup ? -1 : null" />
              </mat-form-field>
              <mat-form-field [floatLabel]="floatLabels" fxFlex="250px">
                <mat-label>{{'common.Descr' | translate}}</mat-label>
                <input matInput formControlName="descr" maxlength="40" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
                <mat-label>{{'common.Type'|translate}}</mat-label>
                <mat-select id="type_id" formControlName="type_id">
                  <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                  <mat-option *ngFor="let typ of whtypes" [value]="typ.itemNumber">
                    {{typ.itemNumber}} {{typ.description}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
                <mat-label>{{'menu.CalendarPractice'|translate}}</mat-label>
                <mat-select id="calrule_id" formControlName="calrule_id">
                  <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                  <mat-option *ngFor="let calrules of calendarrules" [value]="calrules.calrule_id">
                    {{calrules.calrule_id}} {{calrules.descr}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
                <mat-label>{{'menu.BalanceCalc'|translate}}</mat-label>
                <mat-select id="calc_rule_id" formControlName="calc_rule_id">
                  <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                  <mat-option *ngFor="let calrules of balcalcrules" [value]="calrules.calc_rule_id">
                    {{calrules.calc_rule_id}} {{calrules.descr}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
                <mat-label>{{'menu.BalanceDisp'|translate}}</mat-label>
                <mat-select id="baldisprule_id" formControlName="baldisprule_id">
                  <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                  <mat-option *ngFor="let disprules of baldisprules" [value]="disprules.baldisprule_id">
                    {{disprules.baldisprule_id}} {{disprules.description}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
                <mat-label>{{'menu.OvertimePractice'|translate}}</mat-label>
                <mat-select id="overtime_rule_id" formControlName="overtime_rule_id">
                  <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                  <mat-option *ngFor="let overtrule of ovtrules" [value]="overtrule.overtime_id">
                    {{overtrule.overtime_id}} {{overtrule.description}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
                <mat-label>{{'menu.SalaryPeriodGroup'|translate}}</mat-label>
                <mat-select id="sp_grp_id" formControlName="sp_grp_id">
                  <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                  <mat-option *ngFor="let salprdgrp of salprdgroups" [value]="salprdgrp.sp_grp_id">
                    {{salprdgrp.sp_grp_id}} {{salprdgrp.descr}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxFlex.gt-sm="50%">
          <div fxLayout="column" fxLayoutGap="2px">
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
                <mat-label>{{'common.Company'|translate}}</mat-label>
                <mat-select id="company_id" formControlName="company_id">
                  <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                  <mat-option *ngFor="let cmp of companies" [value]="cmp.company_id">
                    {{cmp.number}} {{cmp.descr}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
                <mat-label>{{'wtgroup.AbsReasonCode' | translate}}</mat-label>
                <mat-select id="abs_reasoncode_id" formControlName="abs_reasoncode_id">
                  <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                  <mat-option *ngFor="let ac of absreasoncodes" [value]="ac.number">
                    {{ ac.number }} {{ ac.infotext }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
                <mat-label>{{'wtgroup.FlexReasonCode' | translate}}</mat-label>
                <mat-select id="flex_reasoncode_id" formControlName="flex_reasoncode_id">
                  <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                  <mat-option *ngFor="let ac of absreasoncodes" [value]="ac.number">
                    {{ ac.number }} {{ ac.infotext }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <app-time-converter [label]="roundingLimitLabel"
                                  [timeInMinutes]="wtGroupForm.get('rounding_lim')?.value"
                                  (timeChange)="onTimeUpdated1($event)"></app-time-converter>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <app-time-converter [label]="roundingPeriodLabel"
                                  [timeInMinutes]="wtGroupForm.get('rounding_per')?.value"
                                  (timeChange)="onTimeUpdated2($event)"></app-time-converter>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-slide-toggle formControlName="passivated">{{'wtgroup.Passivated' | translate}}</mat-slide-toggle>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-slide-toggle formControlName="transfer_system1">{{'AbsenceCode.TransferSystem1' | translate}}</mat-slide-toggle>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-slide-toggle formControlName="transfer_system2">{{'AbsenceCode.TransferSystem2' | translate}}</mat-slide-toggle>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-slide-toggle formControlName="noFlexSaldoChgOnWholeDayAbs">{{'wtgroup.WholeDayAbsence' | translate}}</mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>&nbsp;</div>
    <div mat-dialog-actions style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <button mat-raised-button (click)="saveWtGroup();" [disabled]="!writeRight || !wtGroupForm.dirty" color="primary">{{'common.Save' | translate}}</button>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
      <div class="record-count">
        {{'common.Record' | translate}} {{currentIndex + 1}} / {{wtGroups.length}}
      </div>
    </div>
  </div>
</form>
















