import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AlertService, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { TaBasetableService } from '../../services/tabasetable.service';
import { AbsenceCodeService } from "../../services/absencecode.service";
import { BaseTable } from '../../models/basetable.model';
import { TabasetableDialogComponent } from './tabasetable-dialog.component';
import { ApiResult } from '../../base.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AbsenceCode } from '../../models/absencecode.model';
import { AcceptLevelService } from '../../services/acceptlevel.service';
import { WorkNumber } from '../../models/worknumber.model';

@Component({
  selector: 'tabasetable',
  templateUrl: './tabasetable.component.html',
  styleUrls: ['./tabasetable.component.scss']
})
export class TaBasetableComponent implements OnInit, AfterViewInit {

  displayedColumns: string[];
  dataSource: MatTableDataSource<BaseTable | AbsenceCode | WorkNumber>;
  public basetables: MatTableDataSource<BaseTable>;
  public absencecodetables: MatTableDataSource<AbsenceCode>;
  public worknumbertables: MatTableDataSource<WorkNumber>;
  baseTable: BaseTable = new BaseTable();
  absenceCodeTable: AbsenceCode = new AbsenceCode();
  worknumberTable: WorkNumber = new WorkNumber();
  editingBaseTableName: { name: string };
  public show: boolean = true;
  defaultPageIndex: number = 0;
  defaultPageSize: number = 10;
  public defaultSortColumn: string = "number";
  public defaultSortOrder: string = "asc";
  siteName: string;
  userName: string;
  defaultFilterColumn: string = "description";
  filterQuery: string = null;
  tableNumber: number = 0;
  createNew: boolean = false;
  newTableText: string = null;
  editTableText: string = null;
  tableText: string = null;
  message: string = null;
  numberOfRows: number = 0;
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filterTextChanged: Subject<string> = new Subject<string>();

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private localStorage: LocalStoreManager,
    private tabasetableService: TaBasetableService,
    //private absencecodeService: AbsenceCodeService,
    //private acceptlevelService: AcceptLevelService,
    private dialog: MatDialog,
    private router: Router
  ) {
    //this.dataSource = new MatTableDataSource();
  }

  get canManageTables() {
    return true;
  }

  ngOnInit() {
    //var hrefStr = this.router.url;
    //this.tableNumber = Number(hrefStr.substr(hrefStr.length - 1));
    const match = this.router.url.match(/tabasetable(\d+)$/);
    this.tableNumber = match ? parseInt(match[1], 10) : NaN;
    this.setupTableText();
    this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 1024) == 1024;
  }

  setupTableText() {
    switch (this.tableNumber) {
      case 3:
        this.tableText = this.translationService.getTranslation('tabasetable.CircAdd');
        this.newTableText = this.translationService.getTranslation('tabasetable.NewCircAdd');
        this.editTableText = this.translationService.getTranslation('tabasetable.EditCircAdd');
        break;
      case 4:
        this.tableText = this.translationService.getTranslation('tabasetable.ExceptDaytypes');
        this.newTableText = this.translationService.getTranslation('tabasetable.NewExceptDaytypes');
        this.editTableText = this.translationService.getTranslation('tabasetable.EditExceptDaytypes');
        break;
      case 5:
        this.tableText = this.translationService.getTranslation('menu.AbsenceCode');
        this.newTableText = this.translationService.getTranslation('AbsenceCode.NewAbsenceCodes');
        this.editTableText = this.translationService.getTranslation('AbsenceCode.EditAbsenceCode');
        break;
      case 6:
        this.tableText = this.translationService.getTranslation('tabasetable.AcceptLevels');
        this.newTableText = this.translationService.getTranslation('tabasetable.NewAcceptLevels');
        this.editTableText = this.translationService.getTranslation('tabasetable.EditAcceptLevels');
        break;
      case 7:
        this.tableText = this.translationService.getTranslation('tabasetable.JobTasks');
        this.newTableText = this.translationService.getTranslation('tabasetable.NewJobTasks');
        this.editTableText = this.translationService.getTranslation('tabasetable.EditJobTasks');
        break;
      case 8:
        this.tableText = this.translationService.getTranslation('menu.SalaryPeriodGroup');
        this.newTableText = this.translationService.getTranslation('tabasetable.NewSalaryPeriodGroup');
        this.editTableText = this.translationService.getTranslation('tabasetable.EditSalaryPeriodGroup');
        break;
      case 9:
        this.tableText = this.translationService.getTranslation('tabasetable.WorkAdditions');
        this.newTableText = this.translationService.getTranslation('tabasetable.NewWorkAdditions');
        this.editTableText = this.translationService.getTranslation('tabasetable.EditWorkAdditions');
        break;
    }
    console.log("Table number:", this.tableNumber);
    console.log(typeof this.tableNumber); 
    switch (this.tableNumber) {
      case 3:
        this.displayedColumns = ['numberStr', 'description', 'extraDate', 'actions'];  // Include extraDate
        break
      case 4:
      case 8:
        this.displayedColumns = ['number', 'description', 'extraNumber', 'actions'];  // Include extraNumber
        break;
      case 5:
        this.displayedColumns = ['number', 'title', 'infotext', 'description', 'actions'];  // Exclude extra data
        break;
      case 7:
        this.displayedColumns = ['tasknumber', 'description', 'actions'];  // Työmääräimet
        this.defaultSortColumn = "tasknumber";
        break;
      default:
        this.displayedColumns = ['number', 'description', 'actions'];  // Hyväksymistasot
    }
    this.defaultSortColumn = "number";
   
  }

  onFilterTextChanged(filterText: string) {
    if (this.filterTextChanged.observers.length === 0) {
      this.filterTextChanged
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(query => {
          this.loadData(query);
        });
    }
    this.filterTextChanged.next(filterText);
  }

  loadData(query: string = null) {
    var pageEvent = new PageEvent();
    pageEvent.pageIndex = this.defaultPageIndex;
    pageEvent.pageSize = this.defaultPageSize;
    if (query) {
      this.filterQuery = query;
    }
    else
      this.filterQuery = null;
    this.getData(pageEvent);
  }

  getData(event: PageEvent) {

    var sortColumn = (this.sort)
      ? this.sort.active
      : this.defaultSortColumn;

    var sortOrder = (this.sort)
      ? this.sort.direction
      : this.defaultSortOrder;

    var filterColumn = (this.filterQuery)
      ? this.defaultFilterColumn
      : null;

    var filterQuery = (this.filterQuery)
      ? this.filterQuery
      : null;

    if (this.tableNumber === 5) {
      this.tabasetableService.getData<ApiResult<AbsenceCode>>(
        this.tableNumber,
        event.pageIndex,
        event.pageSize,
        sortColumn,
        null,
        filterColumn,
        filterQuery)
        .subscribe(result => {
          this.paginator.length = result.totalCount;
          this.paginator.pageIndex = result.pageIndex;
          this.paginator.pageSize = result.pageSize;
          this.dataSource = new MatTableDataSource(result.data);
          //this.dataSource.paginator = this.paginator;
          this.numberOfRows = this.paginator.length;
          if (this.numberOfRows == 0) {
            var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
            this.alertService.errorMessage(mess);
          }
        },
          error => console.error(error));
    }
    else if (this.tableNumber === 7) {
      this.tabasetableService.getData<ApiResult<WorkNumber>>(
        this.tableNumber,
        event.pageIndex,
        event.pageSize,
        sortColumn,
        null,
        filterColumn,
        filterQuery)
        .subscribe(result => {
          this.paginator.length = result.totalCount;
          this.paginator.pageIndex = result.pageIndex;
          this.paginator.pageSize = result.pageSize;
          this.dataSource = new MatTableDataSource(result.data);
          //this.dataSource.paginator = this.paginator;
          this.numberOfRows = this.paginator.length;
          if (this.numberOfRows == 0) {
            var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
            this.alertService.errorMessage(mess);
          }
        },
          error => console.error(error));
    }
    else {
      this.tabasetableService.getData<ApiResult<BaseTable>>(
        this.tableNumber,
        event.pageIndex,
        event.pageSize,
        sortColumn,
        sortOrder,
        filterColumn,
        filterQuery)
        .subscribe(result => {
          this.paginator.length = result.totalCount;
          this.paginator.pageIndex = result.pageIndex;
          this.paginator.pageSize = result.pageSize;
          this.dataSource = new MatTableDataSource(result.data);
          //this.dataSource.paginator = this.paginator;
          //this.dataSource.sort = this.sort;
          this.numberOfRows = this.paginator.length;
          if (this.numberOfRows == 0) {
            var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
            this.alertService.errorMessage(mess);
          }
        }, error => console.error(error));
    }
  }


  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  private handleMessage(updType: number, data: string) {
    if (data != null) {
      switch (updType) {
        case 0: //insert
          this.alertService.showDialog(this.translationService.getTranslation('notifications.InsertRecordFailed'));
          break;
        case 1: //update
          this.alertService.showDialog(this.translationService.getTranslation('notifications.UpdateRecordFailed'));
          break;
        case 2: //delete
          this.alertService.showDialog(this.translationService.getTranslation('notifications.DeleteRecordFailed'));
          break;
      }
    }
  }

  public createBaseTable() {
    this.createNew = true;
    const dialogRef = this.dialog.open(TabasetableDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: {
          baseTable: null,
          tableNumber: this.tableNumber,
          createNew: this.createNew,
          editText: this.editTableText,
          newText: this.newTableText
        },
      });
    dialogRef.afterClosed().subscribe(() => this.loadData());
  }

  public editBaseTable(baseTable?: any) {
    if (!this.writeRight)
      return;
    baseTable.tableNumber = this.tableNumber;
    this.createNew = false;
    const dialogRef = this.dialog.open(TabasetableDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: {
          baseTable,
          tableNumber: this.tableNumber,
          createNew: this.createNew,
          editText: this.editTableText,
          newText: this.newTableText
        }
      });
    dialogRef.afterClosed().subscribe(() => this.loadData());
  }

  public confirmDelete(baseTable: BaseTable) {
    baseTable.tableNumber = this.tableNumber;
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteRecord(baseTable));
  }


  deleteRecord(baseTable) {
    let id = "";
    if (this.tableNumber == 7 )
      id = baseTable.tasknumber;
    else if (this.tableNumber == 3)
      id = baseTable.numberStr;
    else
      id = baseTable.number.toString();
    this.tabasetableService.deleteBaseTable(id, this.tableNumber)
      .subscribe(results => {
        var mess = this.translationService.getTranslation('notifications.DeleteRecordSucceeded');
        this.alertService.successMessage(mess);
        this.loadData();
      },
        error => {
          //var mess = this.translationService.getTranslation('notifications.DeleteRecordFailed');
          //this.alertService.errorMessage(mess);
        });

  }
}

