import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { OvertimeRule } from '../models/overtimerule.model';

@Injectable()
export class OverTimeRuleService {
  private overtimeRuleUrl: string = 'api/overtimerules';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getOverTimeRules(query: string): Observable<OvertimeRule[]> {
    let endpointUrl = `${this.overtimeRuleUrl}/${query}`;
    return this.http.get<OvertimeRule[]>(endpointUrl);
  }

  updateOvertimeRule(overtimeRule): Observable<OvertimeRule> {
    return this.http.put<OvertimeRule>(this.overtimeRuleUrl, overtimeRule);
  }

  newOvertimeRule(overtimeRule): Observable<OvertimeRule> {
    return this.http.post<OvertimeRule>(this.overtimeRuleUrl, overtimeRule);
  }

  deleteOvertimeRule(id: number) {
    let endpointUrl = `${this.overtimeRuleUrl}/${id}`;
    return this.http.delete<OvertimeRule>(endpointUrl);
  }

}

