import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { PersonNameService } from "../../services/personname.service";
import { SiteService } from '../../services/site.service';
import { Company } from '../../models/company.model';
import { WtGroup } from '../../models/wtgroup.model';
import { Daytype } from '../../models/daytype.model';
import { CalendarRule } from '../../models/calendarrule.model';
import { BalCalcRule } from '../../models/balcalcrule.model';
import { Baldisptype } from '../../models/baldisptype.model';
import { BalDispRule } from '../../models/baldisprule.model';
import { OvertimeRule } from '../../models/overtimerule.model';
import { SalPeriodGroup } from '../../models/salprdgrp.model';
import { AbsenceCode } from '../../models/absencecode.model';
import { AuthService } from '../../services/auth.service';
import { CalendarRuleService } from '../../services/calendarrule.service';
import { BalCalcRuleService } from '../../services/balcalcrule.service';
import { OverTimeRuleService } from '../../services/overtimerule.service';
import { SalPeriodGroupService } from '../../services/salprdgrp.service';
import { AbsenceCodeService } from '../../services/absencecode.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { YesNo } from '../../models/yesno.model';
import { WtGroupService } from '../../services/wtgroup.service';
import { OrganizationService } from '../../services/organization.service';
import { MatTableDataSource } from '@angular/material/table';
import { TimeConverterComponent } from '../../components/time-converter/time-converter.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'wtgroup',
  templateUrl: './wtgroup.component.html',
  styleUrls: ['./wtgroup.component.scss']
})
export class WtGroupComponent implements OnInit, OnChanges {
  @ViewChild('form', { static: true })

  public isNewWtGroup = false;
  wtGroups: WtGroup[] = [];
  wtGroup: WtGroup = null;
  companies: Company[] = [];
  calendarrules: CalendarRule[] = [];
  balcalcrules: BalCalcRule[] = [];
  salprdgroups: SalPeriodGroup[] = [];
  ovtrules: OvertimeRule[] = [];
  absreasoncodes: AbsenceCode[] = [];
  filterQuery: string = null;
  currentIndex:number = 0; // Starts from the first record
  lastIndex = 0;
  adding: boolean = false;
  valid: any = {};
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;
  roundingLimitLabel: string = '';
  roundingPeriodLabel: string = '';

  //filterTextChanged: Subject<string> = new Subject<string>();

  whtypes: Daytype[] = [
    { itemNumber: 1, description: this.translationService.getTranslation('whtype.Whtype1') },
    { itemNumber: 2, description: this.translationService.getTranslation('whtype.Whtype2') },
    { itemNumber: 3, description: this.translationService.getTranslation('whtype.Whtype3') },
    { itemNumber: 4, description: this.translationService.getTranslation('whtype.Whtype4') },
    { itemNumber: 5, description: this.translationService.getTranslation('whtype.Whtype5') },
    { itemNumber: 6, description: this.translationService.getTranslation('whtype.Whtype6') }
  ];

  baldisprules: BalDispRule[] = [
    { baldisprule_id: 1, description: this.translationService.getTranslation('baldisprule.Baldisprule1'), punch_in: 2, punch_out: 6, passivated:false },
    { baldisprule_id: 2, description: this.translationService.getTranslation('baldisprule.Baldisprule2'), punch_in: 2, punch_out: 2, passivated: false },
    { baldisprule_id: 3, description: this.translationService.getTranslation('baldisprule.Baldisprule3'), punch_in: 6, punch_out: 6, passivated: false },
    { baldisprule_id: 4, description: this.translationService.getTranslation('baldisprule.Baldisprule4'), punch_in: 1, punch_out: 5, passivated: false },
    { baldisprule_id: 5, description: this.translationService.getTranslation('baldisprule.Baldisprule5'), punch_in: 1, punch_out: 1, passivated: false },
    { baldisprule_id: 6, description: this.translationService.getTranslation('baldisprule.Baldisprule6'), punch_in: 5, punch_out: 5, passivated: false },
    { baldisprule_id: 7, description: this.translationService.getTranslation('baldisprule.Baldisprule7'), punch_in: 0, punch_out: 0, passivated: false }
  ];

  @Input() isEditMode = true;

  searchForm: FormGroup;
  wtGroupForm: FormGroup;

  get searchName() {
    return this.searchForm.get('searchName');
  }
  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }


  constructor(
    public dialog: MatDialog,
    private siteService: SiteService,
    private alertService: AlertService,
    private authService: AuthService,
    private wtGroupService: WtGroupService,
    private translationService: AppTranslationService,
    private organizationService: OrganizationService,
    private calendarRuleService: CalendarRuleService,
    private balcalcRuleService: BalCalcRuleService,
    private overtimeRuleService: OverTimeRuleService,
    private salperiodGroupService: SalPeriodGroupService,
    private absenceCodeService: AbsenceCodeService,
    private formBuilder: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.searchForm = this.formBuilder.group({
      searchName: [''], // Initialize your search field
    });
    this.buildForm();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.roundingLimitLabel = this.translationService.getTranslation('wtgroup.RoundingLim');
    this.roundingPeriodLabel = this.translationService.getTranslation('wtgroup.RoundingPer');
    this.organizationService.getCompanies()
      .subscribe(data => {
        this.companies = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.calendarRuleService.getcalendarrules()
      .subscribe(data => {
        this.calendarrules = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.balcalcRuleService.getbalcalcrules("null")
      .subscribe(data => {
        this.balcalcrules = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.overtimeRuleService.getOverTimeRules("null")
      .subscribe(data => {
        this.ovtrules = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.salperiodGroupService.getsalprdgroups()
      .subscribe(data => {
        this.salprdgroups = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.absenceCodeService.getSpecificAbsenceCode(3)
      .subscribe(data => {
        this.absreasoncodes = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 1024) == 1024;

  }

  clearSearchField(): void {
    this.searchForm.get('searchName').setValue(''); // This sets the searchName control to an empty string
  }

  loadData(query: string = null) {
    if (query) {
      this.filterQuery = query;
    }
    else
      this.filterQuery = this.searchForm.get('searchName').value === '' ? null : this.searchForm.get('searchName').value;
      this.wtGroupService.getwtgroups(this.filterQuery)
      .subscribe(data => {
        this.wtGroups = data;
        if (this.wtGroups.length > 0) {
          this.wtGroup = this.wtGroups[this.currentIndex];
          this.lastIndex = this.wtGroups.length - 1;
          this.updateFormValues();
        }
        else {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            this.authService.refresh_userdata(data);
          },
            (error: any) => {
            });
      },
        (error: any) => {
          //console.log(error);
        });
  }

  showNextRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex++;
      this.wtGroup = this.wtGroups[this.currentIndex];
      this.updateFormValues();
    }
  }

  showPreviousRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.wtGroup = this.wtGroups[this.currentIndex];
      this.updateFormValues();
    }
  }

  showFirstRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex = 0;
      this.wtGroup = this.wtGroups[this.currentIndex];
      this.updateFormValues();
    }
  }

  showLastRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex = this.lastIndex;
      this.wtGroup = this.wtGroups[this.currentIndex];
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.isNewWtGroup) {
      this.resetForm();
    } else {
      const currentGroup = this.wtGroups[this.currentIndex];
      this.wtGroupForm.patchValue({
        searchName: null,
        wh_group_id: currentGroup.wh_group_id,
        type_id: currentGroup.type_id,
        descr: currentGroup.descr,
        company_id: currentGroup.company_id,
        calc_rule_id: currentGroup.calc_rule_id,
        calrule_id: currentGroup.calrule_id,
        baldisprule_id: currentGroup.baldisprule_id,
        overtime_rule_id: currentGroup.overtime_rule_id,
        sp_grp_id: currentGroup.sp_grp_id,
        rsncdrule_id: currentGroup.rsncdrule_id,
        rounding_lim: currentGroup.rounding_lim,
        rounding_per: currentGroup.rounding_per,
        abs_reasoncode_id: currentGroup.abs_reasoncode_id,
        flex_reasoncode_id: currentGroup.flex_reasoncode_id,
        passivated: currentGroup.passivated,
        transfer_system1: currentGroup.transfer_system1,
        transfer_system2: currentGroup.transfer_system2,
        noFlexSaldoChgOnWholeDayAbs: currentGroup.noFlexSaldoChgOnWholeDayAbs,
        jobnumber_nobreak: currentGroup.jobnumber_nobreak
      });
    }
  }


  ngOnChanges() {
    this.resetForm();
  }

  createWtGroup() {
    this.isNewWtGroup = true; 
    this.wtGroup = new WtGroup(); 
    this.resetForm(); 
    this.clearFormFields();
    this.updateFormValues(); 
    this.currentIndex = 0;
  }

  public saveWtGroup() {
    if (!this.wtGroupForm.valid) {
      this.alertService.errorMessage("Validation error");
      return;
    }
    var editedWtGroup = this.getEditedWtGroup();
    this.wtGroupForm.markAsPristine();

    if (this.isNewWtGroup) {
      this.wtGroupService.newWtGroup(editedWtGroup)
        .subscribe(id => {
          this.saveSuccessHelper(editedWtGroup)
          this.isNewWtGroup = false;
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    this.searchForm.get('searchName').setValue(editedWtGroup.descr);
    this.wtGroupService.updateWtGroup(editedWtGroup).subscribe(
      response => this.saveSuccessHelper(editedWtGroup),
      error => {
        if (error == "Unauthorized")
          this.authService.logout();
        else
          this.saveFailedHelper(error);
      });
    this.clearSearchField();
  }

  onInputChange(field: string, event: Event) {
    const value = (event.target as HTMLInputElement).value;
    console.log("OnInputChange F " + field + " V " + value);
    this.wtGroupForm.markAsDirty();
  }

  private getEditedWtGroup(): WtGroup {
    const formModel = this.wtGroupForm.value;
    return {
      wh_group_id: formModel.wh_group_id,
      descr: formModel.descr,
      company_id: formModel.company_id,
      type_id: formModel.type_id,
      calrule_id: formModel.calrule_id,
      calc_rule_id: formModel.calc_rule_id,
      baldisprule_id: formModel.baldisprule_id,
      rsncdrule_id: formModel.rsncdrule_id,
      overtime_rule_id: formModel.overtime_rule_id,
      sp_grp_id: formModel.sp_grp_id,
      abs_reasoncode_id: formModel.abs_reasoncode_id,
      flex_reasoncode_id: formModel.flex_reasoncode_id,
      rounding_lim: formModel.rounding_lim,
      rounding_per: formModel.rounding_per,
      passivated: formModel.passivated,
      noFlexSaldoChgOnWholeDayAbs: formModel.noFlexSaldoChgOnWholeDayAbs,
      transfer_system1: formModel.transfer_system1,
      transfer_system2: formModel.transfer_system2,
      jobnumber_nobreak: formModel.jobnumber_nobreak
    };
  }

  private saveSuccessHelper(wtGroup?: WtGroup) {
    let mess: string;
    this.loadData();
    if (this.isNewWtGroup) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
    mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
  }

  private saveFailedHelper(wtGroup?: WtGroup) {
  }

  private buildForm() {
    this.wtGroupForm = this.formBuilder.group({
      searchName: null,
      wh_group_id: null,
      descr: null,
      company_id: null,
      type_id: null,
      calrule_id: null,
      calc_rule_id: null,
      baldisprule_id: null,
      rsncdrule_id: null,
      overtime_rule_id: null,
      sp_grp_id: null,
      abs_reasoncode_id: null,
      flex_reasoncode_id: null,
      rounding_lim: null,
      rounding_per: null,
      passivated: false,
      noFlexSaldoChgOnWholeDayAbs: false,
      transfer_system1: false,
      transfer_system2: false,
      jobnumber_nobreak: false
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }
    this.clearFormFields();
    this.wtGroupForm.reset({
      wh_group_id: null,
      descr: null,
      company_id: null,
      type_id: null,
      calrule_id: null,
      calc_rule_id: null,
      baldisprule_id: null,
      rsncdrule_id: null,
      overtime_rule_id: null,
      sp_grp_id: null,
      abs_reasoncode_id: null,
      flex_reasoncode_id: null,
      rounding_lim: null,
      rounding_per: null,
      passivated: false,
      noFlexSaldoChgOnWholeDayAbs: false,
      transfer_system1: false,
      transfer_system2: false,
      jobnumber_nobreak: false
    });

  }

  private clearFormFields() {
  }

  public confirmDelete(wtGroup: WtGroup) {
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteWtGroup(wtGroup));
  }

  deleteWtGroup(wtGroup: WtGroup) {
    this.wtGroupService.deleteWtGroup(wtGroup.wh_group_id)
      .subscribe(results => {
        var mess = this.translationService.getTranslation('notifications.DeleteRecordSucceeded');
        this.alertService.successMessage(mess);
        this.loadData();
      },
        error => {
        });
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }

  onTimeUpdated1(newTime: number | null) {
    this.wtGroupForm.get('rounding_lim')?.setValue(newTime);
    this.wtGroupForm.markAsDirty();
  }

  onTimeUpdated2(newTime: number | null) {
    this.wtGroupForm.get('rounding_per')?.setValue(newTime);
    this.wtGroupForm.markAsDirty();
  }

  cancel(): void {
    this.isNewWtGroup = false; 
    this.resetForm();
    this.wtGroup = this.wtGroups[this.currentIndex];
    this.updateFormValues();
    this.loadData();
  }

  get canManageWtGroups() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}

 


