import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { SaldoNumberService } from "../../services/saldonumber.service";
import { SaldoRelationService } from "../../services/saldorelation.service";
import { BalanceCutService } from '../../services/balancecut.service';
import { BalCalcRule } from '../../models/balcalcrule.model';
import { SaldoNumber } from '../../models/saldonumber.model';
import { BalanceCut } from '../../models/balancecut.model';
import { SalPeriodGroup } from '../../models/salprdgrp.model';
import { SaldoRelation, SaldoRelationColumns } from '../../models/saldorelation.model';
import { AbsenceCode } from '../../models/absencecode.model';
import { AuthService } from '../../services/auth.service';
import { BalCalcRuleService } from '../../services/balcalcrule.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { TimeConverterComponent } from '../../components/time-converter/time-converter.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'balancecalc',
  templateUrl: './balancecalc.component.html',
  styleUrls: ['./balancecalc.component.scss']
})
export class BalanceCalcComponent implements OnInit, OnChanges {
  @ViewChild('form', { static: true })

  public isNewBalanceCalc = false;
  balanceCalcs: BalCalcRule[] = [];
  saldoNumbers: SaldoNumber[] = [];
  balanceCuts: BalanceCut[] = [];
  balanceCalc: BalCalcRule = null;
  displayedColumns: string[] = SaldoRelationColumns.map((col) => col.key)
  columnsSchema: any = SaldoRelationColumns
  dataSource = new MatTableDataSource<SaldoRelation>();
  //public saldorelations: MatTableDataSource<SaldoRelation>;
  saldoRels: SaldoRelation[] = [];
  filterQuery: string = null;
  currentIndex:number = 0; // Starts from the first record
  lastIndex = 0;
  isEdit: boolean = false;
  adding: boolean = false;
  valid: any = {};
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  @Input() isEditMode = true;

  searchForm: FormGroup;
  balanceCalcForm: FormGroup;

  get searchName() {
    return this.searchForm.get('searchName');
  }

  get calc_rule_id() {
    return this.balanceCalcForm.get('calc_rule_id');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private balanceCalcService: BalCalcRuleService,
    private balanceCutService: BalanceCutService,
    private saldoNumberService: SaldoNumberService,
    private saldoRelationService: SaldoRelationService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.searchForm = this.formBuilder.group({
      searchName: [''], // Initialize your search field
    });
    this.buildForm();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    SaldoRelationColumns[0].label = this.translationService.getTranslation('balancecalc.SaldoNumber');
    SaldoRelationColumns[1].label = this.translationService.getTranslation('common.Descr');
    SaldoRelationColumns[2].label = this.translationService.getTranslation('balancecalc.CutMethod');
    SaldoRelationColumns[3].label = this.translationService.getTranslation('common.Descr');
    
    this.saldoNumberService.getsaldonumbers(0)
      .subscribe(data => {
        this.saldoNumbers = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.balanceCutService.getbalancecuts("null")
      .subscribe(data => {
        this.balanceCuts = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 1024) == 1024;
  }

  clearSearchField(): void {
    this.searchForm.get('searchName').setValue(''); // This sets the searchName control to an empty string
  }

  loadData(query: string = null) {
    if (query) {
      this.filterQuery = query;
    }
    else
      this.filterQuery = this.searchForm.get('searchName').value === '' ? null : this.searchForm.get('searchName').value;
    this.balanceCalcService.getbalcalcrulerelations(this.filterQuery)
      .subscribe(data => {
        this.balanceCalcs = data;
        if (this.balanceCalcs.length > 0) {
          this.balanceCalc = this.balanceCalcs[this.currentIndex];
          this.lastIndex = this.balanceCalcs.length - 1;
          this.dataSource.data = this.balanceCalcs[this.currentIndex].saldoRelations;
          this.updateFormValues();
        }
        else {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            this.authService.refresh_userdata(data);
          },
            (error: any) => {
            });
      },
        (error: any) => {
          //console.log(error);
        });
  }

  showNextRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex++;
      this.balanceCalc = this.balanceCalcs[this.currentIndex];
      this.updateFormValues();
    }
  }

  showPreviousRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.balanceCalc = this.balanceCalcs[this.currentIndex];
      this.updateFormValues();
    }
  }

  showFirstRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex = 0;
      this.balanceCalc = this.balanceCalcs[this.currentIndex];
      this.updateFormValues();
    }
  }

  showLastRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex = this.lastIndex;
      this.balanceCalc = this.balanceCalcs[this.currentIndex];
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.isNewBalanceCalc) {
      this.resetForm();
    } else {
      const currentBalanceCalc = this.balanceCalcs[this.currentIndex];
      this.balanceCalcForm.patchValue({
        searchName: null,
        calc_rule_id: currentBalanceCalc.calc_rule_id,
        descr: currentBalanceCalc.descr,
        passivated: currentBalanceCalc.passivated
      });
      this.dataSource.data = currentBalanceCalc.saldoRelations || [];
    }
  }

  ngOnChanges() {
    this.resetForm();
  }

  createBalanceCut() {
    this.isNewBalanceCalc = true; 
    this.balanceCalc = new BalCalcRule(); 
    this.resetForm(); 
    this.clearFormFields();
    this.updateFormValues(); 
    this.currentIndex = 0;
  }

  public saveBalanceCalc() {
    if (!this.balanceCalcForm.valid) {
      this.alertService.errorMessage("Validation error");
      return;
    }
    var editedBalanceCalc = this.getEditedBalanceCalc();
    this.balanceCalcForm.markAsPristine();

    if (this.isNewBalanceCalc) {
      this.balanceCalcService.newBalanceCalc(editedBalanceCalc)
        .subscribe(id => {
          this.saveSuccessHelper(editedBalanceCalc)
          this.isNewBalanceCalc = false;
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    this.searchForm.get('searchName').setValue(editedBalanceCalc.descr);
    this.balanceCalcService.updateBalanceCalc(editedBalanceCalc).subscribe(
      response => this.saveSuccessHelper(editedBalanceCalc),
      error => {
        if (error == "Unauthorized")
          this.authService.logout();
        else
          this.saveFailedHelper(error);
      });
    this.clearSearchField();
  }

  onInputChange(field: string, event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.balanceCalcForm.markAsDirty();
  }

  private getEditedBalanceCalc(): BalCalcRule {
    const formModel = this.balanceCalcForm.value;
    return {
      calc_rule_id: formModel.calc_rule_id,
      descr: formModel.descr,
      passivated: formModel.passivated,
      saldoRelations: null
    };
  }

  private saveSuccessHelper(balanceCalc?: BalCalcRule) {
    let mess: string;
    this.loadData();
    if (this.isNewBalanceCalc) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
    mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
  }

  private saveFailedHelper(balanceCalc?: BalCalcRule) {
  }

  private buildForm() {
    this.balanceCalcForm = this.formBuilder.group({
      calc_rule_id: null,
      descr: null,
      passivated: null,
      saldoRelations:null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }
    this.clearFormFields();
    this.balanceCalcForm.reset({
      balance_cut_id: null,
      descr: null,
      passivated: false,
      saldoRelations: null
    });

  }

  private clearFormFields() {
    this.dataSource.data = this.dataSource.data.map(item => ({
      ...item,
      calc_rule_id: null
    }));
    this.dataSource.data = [];
  }

  public confirmDelete(balanceCalc: BalCalcRule) {
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteBalanceCalc(balanceCalc));
  }

  deleteBalanceCalc(balanceCalc: BalCalcRule) {
    this.balanceCalcService.deleteBalanceCalc(balanceCalc.calc_rule_id)
      .subscribe(results => {
        var mess = this.translationService.getTranslation('notifications.DeleteRecordSucceeded');
        this.alertService.successMessage(mess);
        this.loadData();
      },
        error => {
        });
  }

  editRow(row: SaldoRelation) {
    this.adding = false;
    let index = this.balanceCalcs.findIndex(x => x.calc_rule_id === row.calc_rule_id);
    row.calc_rule_id = this.balanceCalc.calc_rule_id;
     if (row.id === -1) {
      this.saldoRelationService.addSaldoRelation(row)
        .subscribe(response => {
          this.alertService.successMessage(this.translationService.getTranslation('notifications.CreateOk'));
          this.loadData();
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.loadData();
          });
    }
    else {
      // this.balanceCalcs[row.id].calc_rule_id = this.balanceCalcs[index].calc_rule_id;
      this.saldoRelationService.updateSaldoRelation(row)
        .subscribe(response => {
          this.alertService.successMessage(this.translationService.getTranslation('notifications.SaveOk'));
          this.loadData();
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.loadData();
          });
    }
  }

  addRow() {
    this.adding = true;
    const newRow: SaldoRelation = {
      id: -1,
      calc_rule_id: this.balanceCalc.calc_rule_id,
      saldonumber_id:0,
      saldonumber_descr:null,
      balance_cut_id:0,
      balance_cut_descr:null,
      isEdit: true,
    }
    this.dataSource.data = [newRow, ...this.dataSource.data]
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }

  confirmDeleteSaldoRelation(row: SaldoRelation) {
    this.saldoRelationService.deleteSaldoRelation(this.calc_rule_id.value, row.saldonumber_id)
      .subscribe(results => {
        this.alertService.successMessage(this.translationService.getTranslation('notifications.DeleteOk'));
        this.loadData();
      },
        error => {
        });
  }

  cancelCalendar() {
  }

  cancel(): void {
    this.isNewBalanceCalc = false; 
    this.resetForm();
    this.balanceCalc = this.balanceCalcs[this.currentIndex];
    this.updateFormValues();
    this.loadData();
  }

  get canManageBalanceCalc() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}

 


