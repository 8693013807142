import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { Company } from '../../models/company.model';
import { OvertimeRule } from '../../models/overtimerule.model';
import { Daytype } from '../../models/daytype.model';
import { SalPeriodGroup } from '../../models/salprdgrp.model';
import { AbsenceCode } from '../../models/absencecode.model';
import { AuthService } from '../../services/auth.service';
import { OverTimeRuleService } from '../../services/overtimerule.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { YesNo } from '../../models/yesno.model';
import { TimeConverterComponent } from '../../components/time-converter/time-converter.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'overtimerule',
  templateUrl: './overtimerule.component.html',
  styleUrls: ['./overtimerule.component.scss']
})
export class OvertimeRuleComponent implements OnInit, OnChanges {
  @ViewChild('form', { static: true })

  public isNewOvertimeRule = false;
  overtimeRules: OvertimeRule[] = [];
  overtimeRule: OvertimeRule = null;
  filterQuery: string = null;
  currentIndex:number = 0; // Starts from the first record
  lastIndex = 0;
  adding: boolean = false;
  valid: any = {};
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;
  AgreedWorkTimeLabel: string = '';
  RegularWorktimeLabel: string = '';
  Overtime1AmountLabel: string = '';
  Overtime2AmountLabel: string = '';
  normalBalanceTimeLabel: string = '';

  @Input() isEditMode = true;

  searchForm: FormGroup;
  overtimeRuleForm: FormGroup;

  get searchName() {
    return this.searchForm.get('searchName');
  }
  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private overtimeRuleService: OverTimeRuleService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.searchForm = this.formBuilder.group({
      searchName: [''], // Initialize your search field
    });
    this.buildForm();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.AgreedWorkTimeLabel = this.translationService.getTranslation('overtimerule.AgreedWorkTime');
    this.RegularWorktimeLabel = this.translationService.getTranslation('overtimerule.RegularWorktime');
    this.Overtime1AmountLabel = this.translationService.getTranslation('overtimerule.Overtime1Amount');
    this.Overtime2AmountLabel = this.translationService.getTranslation('overtimerule.Overtime2Amount');
   // this.normalBalanceTimeLabel = this.translationService.getTranslation('overtimerule.NormalBalanceTime');
    
    //this.absenceCodeService.getSpecificAbsenceCode(3)
    //  .subscribe(data => {
    //    this.absreasoncodes = data;
    //  },
    //    (error: any) => {
    //      //console.log(error);
    //    });
    this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 1024) == 1024;
  }

  clearSearchField(): void {
    this.searchForm.get('searchName').setValue(''); // This sets the searchName control to an empty string
  }

  loadData(query: string = null) {
    if (query) {
      this.filterQuery = query;
    }
    else
      this.filterQuery = this.searchForm.get('searchName').value === '' ? null : this.searchForm.get('searchName').value;
    this.overtimeRuleService.getOverTimeRules(this.filterQuery)
      .subscribe(data => {
        this.overtimeRules = data;
        if (this.overtimeRules.length > 0) {
          this.overtimeRule = this.overtimeRules[this.currentIndex];
          this.lastIndex = this.overtimeRules.length - 1;
          this.updateFormValues();
        }
        else {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            this.authService.refresh_userdata(data);
          },
            (error: any) => {
            });
      },
        (error: any) => {
          //console.log(error);
        });
  }

  showNextRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex++;
      this.overtimeRule = this.overtimeRules[this.currentIndex];
      this.updateFormValues();
    }
  }

  showPreviousRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.overtimeRule = this.overtimeRules[this.currentIndex];
      this.updateFormValues();
    }
  }

  showFirstRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex = 0;
      this.overtimeRule = this.overtimeRules[this.currentIndex];
      this.updateFormValues();
    }
  }

  showLastRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex = this.lastIndex;
      this.overtimeRule = this.overtimeRules[this.currentIndex];
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.isNewOvertimeRule) {
      this.resetForm();
    } else {
      const currentOvertimeRule = this.overtimeRules[this.currentIndex];
      this.overtimeRuleForm.patchValue({
        searchName: null,
        overtime_id: currentOvertimeRule.overtime_id,
        description: currentOvertimeRule.description,
        interpretation: currentOvertimeRule.interpretation,
        length: currentOvertimeRule.length,
        start_date: currentOvertimeRule.start_date,
        rounding_limit: currentOvertimeRule.rounding_limit,
        rounding_period: currentOvertimeRule.rounding_period,
        agreed_worktime: currentOvertimeRule.agreed_worktime,
        regular_worktime: currentOvertimeRule.regular_worktime,
        overtime1_amount: currentOvertimeRule.overtime1_amount,
        overtime2_amount: currentOvertimeRule.overtime2_amount,
        compensation_saldo: currentOvertimeRule.compensation_saldo,
        change_to_overtime2: currentOvertimeRule.change_to_overtime2,
        fixed_overtime_compensation: currentOvertimeRule.fixed_overtime_compensation,
        passivated: currentOvertimeRule.passivated,
        check_max_lunch: currentOvertimeRule.check_max_lunch,
        check_min_flextime: currentOvertimeRule.check_min_flextime,
        check_inflexibletime_absence: currentOvertimeRule.check_inflexibletime_absence,
        check_inflexibletime_lunch: currentOvertimeRule.check_inflexibletime_lunch,
        allways_overwork2_start: currentOvertimeRule.allways_overwork2_start,
        allways_overwork2_end: currentOvertimeRule.allways_overwork2_end,
        holiday_rule: currentOvertimeRule.holiday_rule,
        regular_contract_worktime: currentOvertimeRule.regular_contract_worktime,
        contract1_amount: currentOvertimeRule.contract1_amount
      });
    }
  }


  ngOnChanges() {
    this.resetForm();
  }

  createOvertimeRule() {
    this.isNewOvertimeRule = true; 
    this.overtimeRule = new OvertimeRule(); 
    this.resetForm(); 
    this.clearFormFields();
    this.updateFormValues(); 
    this.currentIndex = 0;
  }

  public saveOvertimeRule() {
    if (!this.overtimeRuleForm.valid) {
      this.alertService.errorMessage("Validation error");
      return;
    }
    var editedOvertimeRule = this.getEditedOvertimeRule();
    this.overtimeRuleForm.markAsPristine();

    if (this.isNewOvertimeRule) {
      this.overtimeRuleService.newOvertimeRule(editedOvertimeRule)
        .subscribe(id => {
          this.saveSuccessHelper(editedOvertimeRule)
          this.isNewOvertimeRule = false;
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    this.searchForm.get('searchName').setValue(editedOvertimeRule.description);
    this.overtimeRuleService.updateOvertimeRule(editedOvertimeRule).subscribe(
      response => this.saveSuccessHelper(editedOvertimeRule),
      error => {
        if (error == "Unauthorized")
          this.authService.logout();
        else
          this.saveFailedHelper(error);
      });
    this.clearSearchField();
  }

  onInputChange(field: string, event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.overtimeRuleForm.markAsDirty();
  }

  private getEditedOvertimeRule(): OvertimeRule {
    const formModel = this.overtimeRuleForm.value;
    return {
      overtime_id: formModel.overtime_id,
      description: formModel.description,
      interpretation: formModel.interpretation,
      length: formModel.length,
      start_date: formModel.start_date,
      rounding_limit: formModel.rounding_limit,
      rounding_period: formModel.rounding_period,
      agreed_worktime: formModel.agreed_worktime,
      regular_worktime: formModel.regular_worktime,
      overtime1_amount: formModel.overtime1_amount,
      overtime2_amount: formModel.overtime2_amount,
      compensation_saldo: formModel.compensation_saldo,
      change_to_overtime2: formModel.change_to_overtime2,
      fixed_overtime_compensation: formModel.fixed_overtime_compensation,
      passivated: formModel.passivated,
      check_max_lunch: formModel.check_max_lunch,
      check_min_flextime: formModel.check_min_flextime,
      check_inflexibletime_absence: formModel.check_inflexibletime_absence,
      check_inflexibletime_lunch: formModel.check_inflexibletime_lunch,
      allways_overwork2_start: formModel.allways_overwork2_start,
      allways_overwork2_end: formModel.allways_overwork2_end,
      holiday_rule: formModel.holiday_rule,
      regular_contract_worktime: formModel.regular_contract_worktime,
      contract1_amount: formModel.contract1_amount
    };
  }

  private saveSuccessHelper(overtimeRule?: OvertimeRule) {
    let mess: string;
    this.loadData();
    if (this.isNewOvertimeRule) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
    mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
  }

  private saveFailedHelper(overtimeRule?: OvertimeRule) {
  }

  private buildForm() {
    this.overtimeRuleForm = this.formBuilder.group({
      overtime_id: null,
      description: null,
      interpretation: null,
      length: null,
      start_date: null,
      rounding_limit: null,
      rounding_period: null,
      agreed_worktime: null,
      regular_worktime: null,
      overtime1_amount: null,
      overtime2_amount: null,
      compensation_saldo: null,
      change_to_overtime2: false, 
      fixed_overtime_compensation: false,
      passivated: false, 
      check_max_lunch: false,
      check_min_flextime: false,
      check_inflexibletime_absence: false,
      check_inflexibletime_lunch: false,
      allways_overwork2_start: null, 
      allways_overwork2_end: null,
      holiday_rule: null,
      regular_contract_worktime: null,
      contract1_amount: null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }
    this.clearFormFields();
    this.overtimeRuleForm.reset({
      overtime_id: null,
      description: null,
      interpretation: null,
      length: null,
      start_date: null,
      rounding_limit: null,
      rounding_period: null,
      agreed_worktime: null,
      regular_worktime: null,
      overtime1_amount: null,
      overtime2_amount: null,
      compensation_saldo: null,
      change_to_overtime2: false,
      fixed_overtime_compensation: false,
      passivated: false,
      check_max_lunch: false,
      check_min_flextime: false,
      check_inflexibletime_absence: false,
      check_inflexibletime_lunch: false,
      allways_overwork2_start: null,
      allways_overwork2_end: null,
      holiday_rule: null,
      regular_contract_worktime: null,
      contract1_amount: null
    });

  }

  private clearFormFields() {
  }

  public confirmDelete(overtimeRule: OvertimeRule) {
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteOvertimeRule(overtimeRule));
  }

  deleteOvertimeRule(overtimeRule: OvertimeRule) {
    this.overtimeRuleService.deleteOvertimeRule(overtimeRule.overtime_id)
      .subscribe(results => {
        var mess = this.translationService.getTranslation('notifications.DeleteRecordSucceeded');
        this.alertService.successMessage(mess);
        this.loadData();
      },
        error => {
        });
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }

  onTimeUpdated1(newTime: number | null) {
    this.overtimeRuleForm.get('agreed_worktime')?.setValue(newTime);
    this.overtimeRuleForm.markAsDirty();
  }

  onTimeUpdated2(newTime: number | null) {
    this.overtimeRuleForm.get('overtime1_amount')?.setValue(newTime);
    this.overtimeRuleForm.markAsDirty();
  }

  onTimeUpdated3(newTime: number | null) {
    this.overtimeRuleForm.get('regular_worktime')?.setValue(newTime);
    this.overtimeRuleForm.markAsDirty();
  }

  onTimeUpdated4(newTime: number | null) {
    this.overtimeRuleForm.get('overtime2_amount')?.setValue(newTime);
    this.overtimeRuleForm.markAsDirty();
  }

  //onTimeUpdated5(newTime: number | null) {
  //  this.balanceCutForm.get('prd_neglimit')?.setValue(newTime);
  //  this.balanceCutForm.markAsDirty();
  //}

  //onTimeUpdated6(newTime: number | null) {
  //  this.balanceCutForm.get('prd_poslimit')?.setValue(newTime);
  //  this.balanceCutForm.markAsDirty();
  //}

  //onTimeUpdated7(newTime: number | null) {
  //  this.balanceCutForm.get('salprd_neglim')?.setValue(newTime);
  //  this.balanceCutForm.markAsDirty();
  //}

  //onTimeUpdated8(newTime: number | null) {
  //  this.balanceCutForm.get('salprd_poslim')?.setValue(newTime);
  //  this.balanceCutForm.markAsDirty();
  //}

  //onTimeUpdated9(newTime: number | null) {
  //  this.balanceCutForm.get('periodNormalBalanceTime')?.setValue(newTime);
  //  this.balanceCutForm.markAsDirty();
  //}

  cancel(): void {
    this.isNewOvertimeRule = false; 
    this.resetForm();
    this.overtimeRule = this.overtimeRules[this.currentIndex];
    this.updateFormValues();
    this.loadData();
  }

  get canManageOvertimeRules() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}

 


