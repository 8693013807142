export class WtGroup {
  wh_group_id: number;
  descr: string;
  company_id: number;
  type_id: number;
  calrule_id: number;
  calc_rule_id: number;
  baldisprule_id: number;
  rsncdrule_id: number;
  overtime_rule_id: number;
  sp_grp_id: number;
  abs_reasoncode_id: number;
  flex_reasoncode_id: number;
  rounding_lim: number;
  rounding_per: number;
  passivated: boolean;
  noFlexSaldoChgOnWholeDayAbs: boolean;
  transfer_system1: boolean;
  transfer_system2: boolean;
  jobnumber_nobreak: boolean;
}
