import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-time-converter',
  templateUrl: './time-converter.component.html',
})
export class TimeConverterComponent {
  @Input() label: string = 'Default Label';
  @Input() timeInMinutes: number | null = null; // Allow null value
  @Output() timeChange = new EventEmitter<number | null>(); // Emit updated time or null

  formattedTime: string = '';

  ngOnChanges() {
    this.formattedTime = this.convertMinutesToTime(this.timeInMinutes);
  }

  convertMinutesToTime(minutes: number | null): string {
    if (minutes === null) {
      return ''; // Show empty string for null
    }
    if (minutes === 0) {
      return '00:00'; // Show 00.00 for 0
    }
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${this.padZero(hours)}:${this.padZero(mins)}`; // Use . separator
  }

  convertTimeToMinutes(time: string): number | null {
    if (!time) {
      return null; // Return null if the input is empty
    }
    const [hours, mins] = time.split(':').map(Number);
    return hours * 60 + mins;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  onTimeChange(event: any) {
    const newTime = this.convertTimeToMinutes(event.target.value); // Convert time to minutes or null
    this.timeChange.emit(newTime); // Emit updated time or null
  }

}
