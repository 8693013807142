export class OvertimeRule {
  overtime_id: number; 
  description: string;
  interpretation: number; 
  length: number;
  start_date: Date; 
  rounding_limit: number; 
  rounding_period: number; 
  agreed_worktime: number; 
  regular_worktime: number; 
  overtime1_amount: number; 
  overtime2_amount: number; 
  compensation_saldo: number; 
  change_to_overtime2: boolean;
  fixed_overtime_compensation: boolean; 
  passivated: boolean; 
  check_max_lunch: boolean; 
  check_min_flextime: boolean; 
  check_inflexibletime_absence: boolean; 
  check_inflexibletime_lunch: boolean; 
  allways_overwork2_start: number; 
  allways_overwork2_end: number; 
  holiday_rule: number; 
  regular_contract_worktime: number; 
  contract1_amount: number; 
  }

