import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { BalanceCut } from '../models/balancecut.model';

@Injectable()
export class BalanceCutService {
  private balanceCutUrl: string = 'api/balancecuts';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getbalancecuts(query: string): Observable<BalanceCut[]> {
    let endpointUrl = `${this.balanceCutUrl}/${query}`;
    return this.http.get<BalanceCut[]>(endpointUrl).pipe();
  }

  updateBalanceCut(balancecut): Observable<BalanceCut> {
    return this.http.put<BalanceCut>(this.balanceCutUrl, balancecut);
  }

  newBalanceCut(balancecut): Observable<BalanceCut> {
    return this.http.post<BalanceCut>(this.balanceCutUrl, balancecut);
  }

  deleteBalanceCut(id: number) {
    let endpointUrl = `${this.balanceCutUrl}/${id}`;
    return this.http.delete<BalanceCut>(endpointUrl);
  }

}

