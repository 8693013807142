import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { CalendarRule } from '../models/calendarrule.model';

@Injectable()
export class CalendarRuleService {
  private calendarruleUrl: string = 'api/calendarrules';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getcalendarrules(): Observable<CalendarRule[]> {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.calendarruleUrl}/${sitename}`;
    return this.http.get<CalendarRule[]>(endpointUrl);
  }

}

