import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { Company } from '../../models/company.model';
import { BalanceCut } from '../../models/balancecut.model';
import { Daytype } from '../../models/daytype.model';
import { SalPeriodGroup } from '../../models/salprdgrp.model';
import { AbsenceCode } from '../../models/absencecode.model';
import { AuthService } from '../../services/auth.service';
import { BalanceCutService } from '../../services/balancecut.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { YesNo } from '../../models/yesno.model';
import { TimeConverterComponent } from '../../components/time-converter/time-converter.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'balancecut',
  templateUrl: './balancecut.component.html',
  styleUrls: ['./balancecut.component.scss']
})
export class BalanceCutComponent implements OnInit, OnChanges {
  @ViewChild('form', { static: true })

  public isNewBalanceCut = false;
  balanceCuts: BalanceCut[] = [];
  balanceCut: BalanceCut = null;
  filterQuery: string = null;
  currentIndex:number = 0; // Starts from the first record
  lastIndex = 0;
  adding: boolean = false;
  valid: any = {};
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;
  negativeCutLabel: string = '';
  positiveCutLabel: string = '';
  negativeAlarmLabel: string = '';
  positiveAlarmLabel: string = '';
  normalBalanceTimeLabel: string = '';

  //whtypes: Daytype[] = [
  //  { itemNumber: 1, description: this.translationService.getTranslation('whtype.Whtype1') },
  //  { itemNumber: 2, description: this.translationService.getTranslation('whtype.Whtype2') },
  //  { itemNumber: 3, description: this.translationService.getTranslation('whtype.Whtype3') },
  //  { itemNumber: 4, description: this.translationService.getTranslation('whtype.Whtype4') },
  //  { itemNumber: 5, description: this.translationService.getTranslation('whtype.Whtype5') },
  //  { itemNumber: 6, description: this.translationService.getTranslation('whtype.Whtype6') }
  //];

  //baldisprules: BalDispRule[] = [
  //  { baldisprule_id: 1, description: this.translationService.getTranslation('baldisprule.Baldisprule1'), punch_in: 2, punch_out: 6, passivated:false },
  //  { baldisprule_id: 2, description: this.translationService.getTranslation('baldisprule.Baldisprule2'), punch_in: 2, punch_out: 2, passivated: false },
  //  { baldisprule_id: 3, description: this.translationService.getTranslation('baldisprule.Baldisprule3'), punch_in: 6, punch_out: 6, passivated: false },
  //  { baldisprule_id: 4, description: this.translationService.getTranslation('baldisprule.Baldisprule4'), punch_in: 1, punch_out: 5, passivated: false },
  //  { baldisprule_id: 5, description: this.translationService.getTranslation('baldisprule.Baldisprule5'), punch_in: 1, punch_out: 1, passivated: false },
  //  { baldisprule_id: 6, description: this.translationService.getTranslation('baldisprule.Baldisprule6'), punch_in: 5, punch_out: 5, passivated: false },
  //  { baldisprule_id: 7, description: this.translationService.getTranslation('baldisprule.Baldisprule7'), punch_in: 0, punch_out: 0, passivated: false }
  //];

  @Input() isEditMode = true;

  searchForm: FormGroup;
  balanceCutForm: FormGroup;

  get searchName() {
    return this.searchForm.get('searchName');
  }
  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private balanceCutService: BalanceCutService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.searchForm = this.formBuilder.group({
      searchName: [''], // Initialize your search field
    });
    this.buildForm();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.negativeCutLabel = this.translationService.getTranslation('balancecut.NegativeCut');
    this.positiveCutLabel = this.translationService.getTranslation('balancecut.PositiveCut');
    this.negativeAlarmLabel = this.translationService.getTranslation('balancecut.NegativeAlarm');
    this.positiveAlarmLabel = this.translationService.getTranslation('balancecut.PositiveAlarm');
    this.normalBalanceTimeLabel = this.translationService.getTranslation('balancecut.NormalBalanceTime');
    
    //this.absenceCodeService.getSpecificAbsenceCode(3)
    //  .subscribe(data => {
    //    this.absreasoncodes = data;
    //  },
    //    (error: any) => {
    //      //console.log(error);
    //    });
    this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 1024) == 1024;
  }

  clearSearchField(): void {
    this.searchForm.get('searchName').setValue(''); // This sets the searchName control to an empty string
  }

  loadData(query: string = null) {
    if (query) {
      this.filterQuery = query;
    }
    else
      this.filterQuery = this.searchForm.get('searchName').value === '' ? null : this.searchForm.get('searchName').value;
      this.balanceCutService.getbalancecuts(this.filterQuery)
      .subscribe(data => {
        this.balanceCuts = data;
        if (this.balanceCuts.length > 0) {
          this.balanceCut = this.balanceCuts[this.currentIndex];
          this.lastIndex = this.balanceCuts.length - 1;
          this.updateFormValues();
        }
        else {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            this.authService.refresh_userdata(data);
          },
            (error: any) => {
            });
      },
        (error: any) => {
          //console.log(error);
        });
  }

  showNextRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex++;
      this.balanceCut = this.balanceCuts[this.currentIndex];
      this.updateFormValues();
    }
  }

  showPreviousRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.balanceCut = this.balanceCuts[this.currentIndex];
      this.updateFormValues();
    }
  }

  showFirstRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex = 0;
      this.balanceCut = this.balanceCuts[this.currentIndex];
      this.updateFormValues();
    }
  }

  showLastRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex = this.lastIndex;
      this.balanceCut = this.balanceCuts[this.currentIndex];
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.isNewBalanceCut) {
      this.resetForm();
    } else {
      const currentBalanceCut = this.balanceCuts[this.currentIndex];
      this.balanceCutForm.patchValue({
        searchName: null,
        balance_cut_id: currentBalanceCut.balance_cut_id,
        descr: currentBalanceCut.descr,
        daily_neglimit: currentBalanceCut.daily_neglimit,
        daily_poslimit: currentBalanceCut.daily_poslimit,
        prd_duration: currentBalanceCut.prd_duration,
        prd_firstday: currentBalanceCut.prd_firstday,
        prd_neglimit: currentBalanceCut.prd_neglimit,
        prd_poslimit: currentBalanceCut.prd_poslimit,
        salprd_day: currentBalanceCut.salprd_day,
        salprd_neglim: currentBalanceCut.salprd_neglim,
        salprd_poslim: currentBalanceCut.salprd_poslim,
        daily_alarmneglimit: currentBalanceCut.daily_alarmneglimit,
        daily_alarmposlimit: currentBalanceCut.daily_alarmposlimit,
        passivated: currentBalanceCut.passivated,
        periodNormalBalanceTime: currentBalanceCut.periodNormalBalanceTime,
        periodNormalLength: currentBalanceCut.periodNormalLength,
        periodNormalStartDate: currentBalanceCut.periodNormalStartDate
      });
    }
  }


  ngOnChanges() {
    this.resetForm();
  }

  createBalanceCut() {
    this.isNewBalanceCut = true; 
    this.balanceCut = new BalanceCut(); 
    this.resetForm(); 
    this.clearFormFields();
    this.updateFormValues(); 
    this.currentIndex = 0;
  }

  public saveBalanceCut() {
    if (!this.balanceCutForm.valid) {
      this.alertService.errorMessage("Validation error");
      return;
    }
    var editedBalanceCut = this.getEditedBalanceCut();
    this.balanceCutForm.markAsPristine();

    if (this.isNewBalanceCut) {
      this.balanceCutService.newBalanceCut(editedBalanceCut)
        .subscribe(id => {
          this.saveSuccessHelper(editedBalanceCut)
          this.isNewBalanceCut = false;
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    this.searchForm.get('searchName').setValue(editedBalanceCut.descr);
    this.balanceCutService.updateBalanceCut(editedBalanceCut).subscribe(
      response => this.saveSuccessHelper(editedBalanceCut),
      error => {
        if (error == "Unauthorized")
          this.authService.logout();
        else
          this.saveFailedHelper(error);
      });
    this.clearSearchField();
  }

  onInputChange(field: string, event: Event) {
    const value = (event.target as HTMLInputElement).value;
    console.log("OnInputChange F " + field + " V " + value);
    this.balanceCutForm.markAsDirty();
  }

  private getEditedBalanceCut(): BalanceCut {
    const formModel = this.balanceCutForm.value;
    return {
      balance_cut_id: formModel.balance_cut_id,
      descr: formModel.descr,
      daily_neglimit: formModel.daily_neglimit,
      daily_poslimit: formModel.daily_poslimit,
      prd_duration: formModel.prd_duration,
      prd_firstday: formModel.prd_firstday,
      prd_neglimit: formModel.prd_neglimit,
      prd_poslimit: formModel.prd_poslimit,
      salprd_day: formModel.salprd_day,
      salprd_neglim: formModel.salprd_neglim,
      salprd_poslim: formModel.salprd_poslim,
      daily_alarmneglimit: formModel.daily_alarmneglimit,
      daily_alarmposlimit: formModel.daily_alarmposlimit,
      passivated: formModel.passivated,
      periodNormalBalanceTime: formModel.periodNormalBalanceTime,
      periodNormalLength: formModel.periodNormalLength,
      periodNormalStartDate: formModel.periodNormalStartDate
    };
  }

  private saveSuccessHelper(balanceCut?: BalanceCut) {
    let mess: string;
    this.loadData();
    if (this.isNewBalanceCut) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
    mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
  }

  private saveFailedHelper(balanceCut?: BalanceCut) {
  }

  private buildForm() {
    this.balanceCutForm = this.formBuilder.group({
      balance_cut_id: null,
      descr: null,
      daily_neglimit: null,
      daily_poslimit: null,
      prd_duration: null,
      prd_firstday: null,
      prd_neglimit: null,
      prd_poslimit: null,
      salprd_day: null,
      salprd_neglim: null,
      salprd_poslim: null,
      daily_alarmneglimit: null,
      daily_alarmposlimit: null,
      passivated: false,
      periodNormalBalanceTime: null,
      periodNormalLength: null,
      periodNormalStartDate: null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }
    this.clearFormFields();
    this.balanceCutForm.reset({
      balance_cut_id: null,
      descr: null,
      daily_neglimit: null,
      daily_poslimit: null,
      prd_duration: null,
      prd_firstday: null,
      prd_neglimit: null,
      prd_poslimit: null,
      salprd_day: null,
      salprd_neglim: null,
      salprd_poslim: null,
      daily_alarmneglimit: null,
      daily_alarmposlimit: null,
      passivated: false,
      periodNormalBalanceTime: null,
      periodNormalLength: null,
      periodNormalStartDate: null
    });

  }

  private clearFormFields() {
  }

  public confirmDelete(balanceCut: BalanceCut) {
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteBalanceCut(balanceCut));
  }

  deleteBalanceCut(balanceCut: BalanceCut) {
    this.balanceCutService.deleteBalanceCut(balanceCut.balance_cut_id)
      .subscribe(results => {
        var mess = this.translationService.getTranslation('notifications.DeleteRecordSucceeded');
        this.alertService.successMessage(mess);
        this.loadData();
      },
        error => {
        });
  }

  onTimeUpdated1(newTime: number | null) {
    this.balanceCutForm.get('daily_neglimit')?.setValue(newTime);
    this.balanceCutForm.markAsDirty();
  }

  onTimeUpdated2(newTime: number | null) {
    this.balanceCutForm.get('daily_poslimit')?.setValue(newTime);
    this.balanceCutForm.markAsDirty();
  }

  onTimeUpdated3(newTime: number | null) {
    this.balanceCutForm.get('daily_alarmneglimit')?.setValue(newTime);
    this.balanceCutForm.markAsDirty();
  }

  onTimeUpdated4(newTime: number | null) {
    this.balanceCutForm.get('daily_alarmposlimit')?.setValue(newTime);
    this.balanceCutForm.markAsDirty();
  }

  onTimeUpdated5(newTime: number | null) {
    this.balanceCutForm.get('prd_neglimit')?.setValue(newTime);
    this.balanceCutForm.markAsDirty();
  }

  onTimeUpdated6(newTime: number | null) {
    this.balanceCutForm.get('prd_poslimit')?.setValue(newTime);
    this.balanceCutForm.markAsDirty();
  }

  onTimeUpdated7(newTime: number | null) {
    this.balanceCutForm.get('salprd_neglim')?.setValue(newTime);
    this.balanceCutForm.markAsDirty();
  }

  onTimeUpdated8(newTime: number | null) {
    this.balanceCutForm.get('salprd_poslim')?.setValue(newTime);
    this.balanceCutForm.markAsDirty();
  }

  onTimeUpdated9(newTime: number | null) {
    this.balanceCutForm.get('periodNormalBalanceTime')?.setValue(newTime);
    this.balanceCutForm.markAsDirty();
  }

  cancel(): void {
    this.isNewBalanceCut = false; 
    this.resetForm();
    this.balanceCut = this.balanceCuts[this.currentIndex];
    this.updateFormValues();
    this.loadData();
  }

  get canManageBalanceGroups() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}

 


